
.show-individual {
  margin-right: 15px !important;
}

.portal-container {
  .selected-row {
    background-color: #EBF2FF !important;
  }

  .highlight-on-hover {
    &:hover {
      background-color: lighten(#E0FFFF, 2%) !important;
      cursor: pointer;
    }

    &.selected-row {
      background-color: #EBF2FF !important;
    }
  }
}
