
.money-back {
  padding: 40px;
  // padding-top: 0;
  font-weight: 500;
  background-color: $theme-light-grey;

  &.white-background {
    padding: 0;
    background-color: white;
  }

  .money-back-wrapper {
    margin: 0 auto;
    text-align: center;
  }
}

.roi-calc {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &.blue-gradient {
    background: rgb(255,255,255);
    background: linear-gradient(lighten($theme-color, 90%), lighten($theme-color, 20%));
  }

  .roi-calc-content {
    flex: 1 0 auto;

    .intro-title {
      font-size: 24px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 40px;
      margin-top: 100px;

      &.thank-you {
        font-size: 36px;
        margin-bottom: 10px;
        font-weight: 500;
        margin-top: 20px;
      }

      @media #{$sm-and-down} {
        margin-bottom: 20px;
        margin-top: 20px;
      }

    }

    .question-title {
      font-size: 28px;
      font-weight: 500;
      text-align: center;
      margin-top: 40px;

      &.white-letters {
        color: white;
        font-weight: 600;
      }

      &.thank-you {
        margin-top: 0;
        margin-bottom: 40px;
        font-size: 24px;
      }

      @media #{$sm-and-down} {
        font-size: 20px;
        margin-top: 20px;
      }
    }

    .roi-logo {
      display: block;
      max-width: 350px;
      margin: 0 auto;
      @media #{$sm-and-down} {
        max-width: 250px;
      }
    }

    .question-icon {
      display: block;
      height: 86px;
      margin: 0 auto;
    }

    .continue-calc {
      margin: 0 auto;
      margin-bottom: 60px;
      display:block;
      max-width: 200px;
      text-transform: uppercase;
      font-weight: 450;
      cursor: pointer;
      @media #{$sm-and-down} {
        margin-bottom: 40px;
      }
    }

    .calc-icon-container {
      margin-top: 10px;
      text-align: center;
    }

    .calc-options-container {
      display:flex;
      align-items:stretch;
      justify-content: center;
      margin: 30px 0 30px;
      @media #{$sm-and-down} {
        margin: 10px 0 10px;
        flex-direction: column;
        align-items: center;
      }

      .calc-options {
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 10px;
        width: 200px;
        border-radius: 3px;
        border: 1px #777 solid;
        margin: 5px;
        font-weight: 550;
        color: #777;
        cursor: pointer;

        &.with-hover{
          text-transform: uppercase;
          &:hover {
            background-color: lighten($theme-color, 20%);
            color: white;

          }
        }
        &.selected {
          background-color: $theme-color !important;
          color: white;
        }
      }
    }
  }

  .roi-calc-footer {
    flex-shrink: 0;
    width: 100%;
    border-top: lighten($theme-gold, 20%) 10px solid;
    text-align: center;

    .roi-savings {
      padding: 20px;
      font-size: 28px;
      font-weight: 800;
      color: lighten($theme-color, 10%);
    }

    .roi-savings-text {
      font-size: 16px;
      font-weight: 800;
      padding-bottom: 20px;
    }
  }

  .roi-results-footer {
    flex-shrink: 0;
    width: 100%;
    margin-top: 20px;
    text-align: center;

    .speak-to-rep {
      border-top: lighten($theme-gold, 20%) 10px solid;
      font-size: 16px;
      font-weight: 800;
      padding-top: 20px;
    }

    .speak-to-rep-btn {
      margin-top: 20px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .gold-border {
    margin: 20px 0;
    border-top: lighten($theme-gold, 20%) 10px solid;
    @media #{$md-and-up} {
      margin: 20px 15% 0 15%;
    }
    margin-top: 20px;
  }

  .savings-line {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 800;
    align-items: center;
    @media #{$sm-and-down} {
      font-size: 16px;
      font-weight: 700;
    }
    @media #{$md-and-up} {
      margin: 20px 15% 0 15%;
    }

    &.total-savings {
      font-size: 24px;
    }


    .savings-description {
      flex-grow: 2;
    }
    .savings-amount {
      text-align: right;
      color: lighten($theme-color, 10%);
      padding-left: 5px;
    }
  }
}
