$admin-border: rgba($theme-black, 0.08);

[data-reach-menu-popover] {
  z-index: 500;
}

.admin-container {
  width: 100%;
}

.right-menu {
  display: flex;
  flex-direction: column;
}

.toggle-menu {
  display: none;
}

.admin-portal-panel {
  @extend %theme-font;

  position: relative;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  border: $theme-light-grey solid 0px;
  border-bottom-width: 1px;

  .content-right-panel {
    width: 100%;
  }

  .admin-left {
    padding-top: 180px;
    width: 20%;
    height: 100vh;
    border: $theme-light-grey solid 0px;
    border-right-width: 1px;

    @media #{$sm-and-down} {
      width: 100%;
      height: auto;
    }
  }

  .admin-right {
    width: 80%;
    display: flex;
    flex: 1 0 auto;
    padding: 10px;
    padding-top: 180px;
    padding-bottom: 110px;
    height: 100vh;
    overflow: auto;

    .loaderWrap {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        position: relative;
        left: auto !important;
        top: auto !important;
      }
    }
  }

  .active-tab {
    border-bottom: 4px solid $theme-color;
    margin-bottom: -2px;
  }

  input#search-field {
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
  }

  select#page-menu {
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    padding: 1px 3px 0 7px;
  }

  h1 {
    font-size: 2rem;
    color: $theme-black;
    margin: 0 0 20px 0;
    padding-bottom: 0;
    overflow: hidden;
    border: none;
  }

  .well {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .panel {
    border: $theme-grey solid 1px;
    box-shadow: none;
    border-radius: 2px;

    .no-border {
      border: none;
    }

    .panel-heading {
      background-color: #fff;
      color: $theme-black;
      border-bottom: $theme-grey solid 1px;
      font-size: 20px;
    }
  }
}

.user-history-content {
  .user-history-row {
    font-size: 1.2rem;
    padding: 10px 0;
    border-bottom: $theme-light-grey solid 1px;
  }
}

.admin-tag-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.admin-banners {
  td > div {
    display: flex;
    align-items: center;
  }
  .admin-banner-image-embed {
    img {
      width: 700px;
      height: auto;
    }
  }
}

.admin-tag-wrapper {
  width: 25%;

  label a {
    display: flex;
    flex-direction: row;

    img {
      margin-right: 5px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.tag-list-heading {
  width: 29%;
  float: left;
  padding: 8px;
  margin-left: 10px;
}

.tag-list {
  padding: 6px 5px 1px;
  margin-top: 10px;
  left: 200px;
  width: 45%;
}

.admin-tab-list {
  margin: 0;
  padding: 0;
  border-bottom: #ccc solid 1px;
  margin-bottom: 10px;
}

.admin-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
}

.admin-tabs {
  font-size: $theme-font-normal !important;
  padding: 0 20px 0 0;
  display: inline-block;
  list-style: none;
  margin-right: 25px;
  text-align: center;
}

.admin-tabs > .profile-category-title {
  font-size: $theme-font-normal !important;
  cursor: pointer;
}

.tiles-admin {
  .tile {
    height: 100px;

    .card-title {
      height: 30px !important;
    }

    .bottom-description {
      height: 70px !important;
    }
  }
}

.enterprise-logo {
  height: var(--chalice-navbar-height);
  width: auto;
  margin: 0 15px 0 0;
}

.admin-dashboard {
  padding: 0 30px;

  .center-panel {
    border: $admin-border solid 1px;
    border-width: 0 1px 0 1px;
    padding: 0 20px;

    @media #{$md-and-down} {
      border-width: 0;
      padding: 0;
    }
  }

  .metrics {
    h2 {
      font-size: 4rem;
      text-align: center;
      font-weight: 400;
    }

    h3 {
      font-size: 1.2rem;
      text-align: center;
      font-weight: 400;
      margin: 5px 0;
    }

    .metric-up {
      color: green;
    }

    .metric-down {
      color: red;
    }
  }

  .graphs {
    padding-bottom: 75px;

    .graph-loader {
      height: 100px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .insight {
      padding-left: 25px;
      padding-right: 100px;
    }
  }

  .admin-member-display {
    h2 {
      font-weight: 400;
      font-size: 1.2rem;
      border-bottom: $admin-border solid 1px;
    }

    .admin-member-entry {
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: center;
      height: 85px;
      box-shadow: 0 0 0 rgba(#000, 0.25);
      transition: all 0.25s;
      color: $theme-black;

      &:hover {
        box-shadow: 0 0 2px rgba(#000, 0.25);
        transform: scale(1.02);
        transition: all 0.25s;
      }

      .body {
        padding: 0 0 0 10px;
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
        overflow: hidden;
      }
    }
  }
}

.admin-profile-image {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 1px solid $admin-border;
  overflow: hidden;
  align-items: center;
  display: flex;
  flex: 0 0 50px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.admin-card {
  .group {
    padding: 0 0 20px 0;
  }

  label {
    font-size: 0.8rem;
    opacity: 0.8;
  }
}

.history-card {
  border: none !important;
  max-width: 500px !important;
  height: 1000 !important;
}

.product-name {
  max-width: 100px;
}

.admin-left-panel {
  border-right: $admin-border solid 1px;
  padding: 0;
}

.admin-right-panel {
  padding: 0;
}

.admin-profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  border-top: $admin-border solid 1px;

  .admin-profile-bottom {
    flex: 0 1 auto;
    padding: 10px;
    text-align: center;
  }
}

.admin-collapsable {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border-top: $admin-border solid 1px;

  label {
    font-size: 0.8rem;
    opacity: 0.8;
  }

  .admin-collapsable-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 0;
    cursor: pointer;

    .arrow {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(0deg);
      transform-origin: center center;
      transition: 0.25s all;
      opacity: 0.5;

      &.down {
        transform: rotate(-90deg);
        transition: 0.25s all;
      }
    }

    h2 {
      font-size: 0.9rem;
      font-weight: 500;
      padding: 0 0 0 10px;
    }
  }

  .admin-collapsable-body {
    transform-origin: top center;
    transform: scaleY(1) !important;
    opacity: 1;
    transition: all 0.25s;
    padding: 20px 0 10px 30px;
    display: block;

    &.collapsed {
      transform: scaleY(1) !important;
      opacity: 0;
      transition: all 0.25s;
      display: none;
    }
  }
}

.admin-textarea {
  height: 200px !important;
}

.admin-textarea-toolbar {
  button,
  span.ql-color {
    opacity: 1;
    transition: 0.25s;

    &.disabled {
      opacity: 0.25;
      transition: 0.25s;
      pointer-events: none;
    }
  }
}

.CodeMirror {
  border: #ccc solid 1px;
  height: 200px;
}

.admin-content-panel {
  flex: 1 0 auto;

  .table-responsive-header {
    table {
      width: 100%;
      max-width: 100%;
      margin: 0;
      border-bottom: none;
      white-space: nowrap;
      background-color: #fff;
      font-size: 0.9rem;
      overflow: hidden;

      .text-nowrap tr th {
        cursor: pointer;
      }
    }

    thead {
      background-color: rgba($theme-color, 0.05);

      th {
        border: none;
        border-right: $admin-border solid 1px;
        color: $theme-black;
        font-weight: 400;
        font-size: 0.9rem;
        height: 1.2rem;
        padding: 15px 10px;
      }
    }
  }

  .validation-error {
    font-size: 12px !important;
    color: red;
  }
}

.admin-table-responsive {
  border: $admin-border solid 1px;
  margin-bottom: 18px;
  min-height: 200px;
  // max-height: 482px !important;

  .no-data {
    padding-top: 4px;
    height: 50px !important;
  }

  .tbl-btn {
    color: black;
    display: flex;
    text-align: center;
    margin: auto;
    padding-left: 12px;
    padding-top: 6px;
    min-width: 36px;
    min-height: 36px;
  }

  .update-btn {
    color: white;
    display: flex;
    text-align: center;
    margin: auto;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    min-width: 36px;
    min-height: 36px;
  }

  .tbl-btn-icon {
    color: black;
    display: flex;
    text-align: center;
    margin: auto;
    padding-left: 9px;
    padding-top: 10px;
    min-width: 36px;
    min-height: 36px;
  }

  table {
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-bottom: none;
    white-space: nowrap;
    background-color: #fff;
    font-size: 0.9rem;

    .text-nowrap tr th {
      cursor: pointer;
    }

    tr {
      td {
        height: 1.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
        border-right: 1px solid #e9ebee;

        a {
          color: $theme-black;
          border-bottom: rgba($theme-black, 0) solid 1px;
          transition: all 0.25s;

          &:hover {
            // border-bottom: rgba($theme-black, 1) solid 1px;
            transition: all 0.25s;
          }
        }
      }
    }
  }

  thead {
    background-color: white !important;

    th {
      position: sticky;
      top: 0;
      // z-index: 1;
      background: #e9ebee;
      border: none;
      border-right: $admin-border solid 1px;
      color: $theme-black;
      font-weight: 400;
      font-size: 0.9rem;
      height: 1.2rem;
      padding: 15px 10px;
    }
  }
}

.admin-push-table {
  border: $admin-border solid 1px;
  margin-bottom: 18px;
  min-height: 200px;
  // max-height: 482px !important;

  thead {
    background-color: rgba($theme-color, 0.05);

    th {
      border: none;
      border-right: $admin-border solid 1px;
      color: $theme-black;
      font-weight: 400;
      font-size: 0.9rem;
      height: 1.2rem;
      padding: 15px 10px;
    }
  }

  .no-data {
    padding-top: 4px;
    height: 50px !important;
  }

  .tbl-btn {
    color: black;
    display: flex;
    text-align: center;
    margin: auto;
    padding-left: 12px;
    padding-top: 6px;
    min-width: 36px;
    min-height: 36px;
  }

  .update-btn {
    color: white;
    display: flex;
    text-align: center;
    margin: auto;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    min-width: 36px;
    min-height: 36px;
  }

  .tbl-btn-icon {
    color: black;
    display: flex;
    text-align: center;
    margin: auto;
    padding-left: 9px;
    padding-top: 10px;
    min-width: 36px;
    min-height: 36px;
  }

  table {
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-bottom: none;
    white-space: nowrap;
    background-color: #fff;
    font-size: 0.9rem;

    .text-nowrap tr th {
      cursor: pointer;
    }

    tr {
      td {
        height: 1.2rem;
        white-space: nowrap;
        vertical-align: middle;
        border-right: 1px solid #e9ebee;

        a {
          color: $theme-black;
          border-bottom: rgba($theme-black, 0) solid 1px;
          transition: all 0.25s;

          &:hover {
            // border-bottom: rgba($theme-black, 1) solid 1px;
            transition: all 0.25s;
          }
        }
      }
    }
  }

  thead {
    background-color: white !important;

    th {
      position: sticky;
      top: 0;
      background: #e9ebee;
      border: none;
      border-right: $admin-border solid 1px;
      color: $theme-black;
      font-weight: 400;
      font-size: 0.9rem;
      height: 1.2rem;
      padding: 15px 10px;
    }
  }
}

.admin-table {
  .small-labels {
    th {
      font-weight: 500 !important;
      font-size: 12px !important;
      letter-spacing: -0.5px;
    }
  }
  .flex-apart {
    display: flex;
    justify-content: space-between;
  }
}

.admin-pm-sortable {
  padding-top: 30px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.admin-pm-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.25;
  max-width: 350px;

  button {
    border: 0;
    border-radius: 0;
    background-color: transparent;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .admin-pm-header,
  .admin-pm-footer {
    position: absolute;
    background-color: rgba(#fff, 0.9);
    opacity: 0;
    transition: all 0.25;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
  }

  .admin-pm-header {
    position: absolute;
    right: 0;
    left: 0;
  }

  .admin-pm-footer {
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: center;
  }

  &:hover {
    transition: all 0.25;

    .admin-pm-header,
    .admin-pm-footer {
      opacity: 0.9 !important;
      transition: all 0.25;
    }
  }
}

.edit-profile-image {
  display: none;
  %edit-btn {
    border-radius: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.admin-dropdown {
  border-bottom: none !important;

  .hide {
    opacity: 0;
    transition: opacity 0.5s;
    border: $theme-grey transparent 1px;
  }

  .admin-dropdown-display {
    z-index: 100;

    a {
      text-align: center;
      padding: 0 10px;
    }
  }

  .admin-dropdown-wrapper {
    position: relative;
    z-index: 99;
  }

  .admin-dropdown-content {
    top: 0;
    opacity: 1;
    border: $theme-light-grey solid 1px;
    border-radius: 2px;
    transition: opacity 0.5s;
    background-color: #fff;
    padding-top: 12px;
    padding-bottom: 12px;
    position: absolute;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    bottom: 50px;
    height: 107px !important;
    align-content: center;

    @extend .boxxed;

    a {
      margin: 0;
      text-decoration: none;
      padding: 10px 20px;
      color: $theme-black;

      &:hover,
      &.active {
        border: none;
        background-color: $theme-light-grey;
        text-decoration: none !important;
      }
    }
  }
}

.admin-search-bar {
  display: block;
  width: 98%;
  margin-top: 1px;
  padding-top: 6px;
  padding-bottom: 7px;
  border-width: 1px;
  border-radius: 5px;
  border-color: #cccccc;
}

.admin-date-range {
  min-height: 37px !important;
  width: 95%;
}

.admin-panel {
  max-width: 1000px;
  margin: 0 auto;
}

.admin-split {
  margin: 40px auto;
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;
  border-bottom: #eee solid 2px;

  @media #{$sm-and-down} {
    flex-direction: column;
  }

  h2 {
    font-size: 1.1rem;
  }

  p {
  }

  .left {
    width: 40%;
    padding-right: 20px;

    @media #{$sm-and-down} {
      width: 100%;
      padding-right: 0;
    }
  }

  .right {
    width: 60%;

    @media #{$sm-and-down} {
      width: 100%;
    }
  }

  .admin-card {
    border-radius: 10px;
    border: #eee solid 2px;
    padding: 20px;
  }
}

.admin-external-link {
  color: $theme-color;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  svg {
    margin-left: 5px;
  }
}

.authwall {
  min-height: 100vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;

  .authwall-container {
    padding: 50px;
    max-width: 500px;
  }

  .message {
    padding: 50px;
  }

  img {
    margin: 0 auto;
    width: 400px;
    max-width: 100%;
  }

  p {
    margin-bottom: 5px;
  }

  h2 {
    font-size: 0.9rem;
  }
}
