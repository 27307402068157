.stripe-control {
  padding: 12px 10px;
  border-radius: 1px;
  border-width: 1px;
  border: 1px solid #ccc;
}

.apply-discount {
  .success-message {
    padding: 20px;
  }
  div {
    padding-left: 0;
  }
  button {
    height: 43px;
  }
}

.coupon-code {
  position: relative;
  margin-top: 5px;

  i,
  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(-100%);
  }
}

.membership-information {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;

  h1 {
    font-weight: 800;
  }

  ul {
    list-style-type: none;
    font-size: 16px;
    padding: 0;
    margin: 0;

    li {
      padding: 5px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        padding: 0 5px 0 0;
        color: $theme-success;
        width: 20px;
      }

      div {
        padding: 0 0 0 10px;
      }

      &.disabled {
        color: $theme-grey;

        i {
          color: $theme-danger;
        }
      }
    }
  }

  .panel {
    margin: 0 10px 10px 0;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 350px;

    .yearly-price {
      color: $theme-grey;
      font-size: 0.95rem;

      strong {
        color: $theme-black;
      }
    }

    &.gold {
      border-top: $theme-gold solid 5px;

      .price {
        color: $theme-color-dark;
      }
    }

    &.free {
      border-top: $theme-color-dark solid 5px;

      .price {
        color: $theme-color-dark;
      }
    }

    .panel-heading {
      font-size: 24px;
      text-align: center;
      padding: 20px;
      flex: 0 0 auto;
    }

    .panel-subheading {
      padding: 20px 10px;
      text-align: center;
      border-bottom: $theme-light-grey solid 1px;
      flex: 0 0 auto;

      .price {
        font-weight: 600;
        color: $theme-color-dark;
        font-size: 2rem;

        .pennies {
          display: inline;
          font-size: 20px;
        }

        span {
          color: $theme-grey;
          font-size: 1.5rem;
        }
      }
    }

    .panel-filler {
      flex: 1 0 auto;
    }

    .panel-description {
      flex: 0 0 auto;
      padding: 10px 20px 0 20px;
      border-bottom: $theme-light-grey solid 1px;
      height: 80px;
      display: flex;
      align-items: center;

      p {
        font-weight: 600;
        text-align: center;
      }
    }

    .panel-body {
      padding: 10px 20px;
      flex: 0 0 auto;

      p {
        font-weight: 600;
      }
    }

    .panel-footer {
      flex: 0 0 auto;
      background-color: #fff;
    }
  }
}

.toggle-frequency {
  border: 0;
}

.thankyou-check {
  color: $theme-success;
  font-size: 5rem;
}

.billing-plan {
  margin-bottom: 5px;
  min-width: 300px;
}

.cards-display {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.card-active {
    background-color: $theme-light-grey
  }

  .card-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.billing-table {
  .row {
    margin-bottom: 15px;
  }
}

.switch-label {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    transition: color 0.25s;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 500;
    color: darken($theme-grey, 5%);
    &.bold-selected {
      color: $theme-black;
    }
  }
}

.radio-container {
  .radio-option {
    font-weight: 500 !important;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
