// $theme-color: #0071ce;
$theme-color: #324b75;
$theme-color-secondary: rgba(0, 113, 206, 0.5);
$theme-color-dark: #324b75;
$theme-color-super-dark: #022241;
$theme-background: #fff;

$theme-grey: #999;
$theme-light-grey: #eee;
$theme-super-light-grey: #e6e6e6;
$theme-black: #0e2240;
$theme-white: #fff;
$theme-gold: #eea904;
$theme-success: #5cb85c;
$theme-danger: #d43f3a;
$theme-background-grey: rgb(250, 250, 250);

$theme-tier-0: #999;
$theme-tier-1: #6c7787;
$theme-tier-2: $theme-color;
$theme-tier-3: $theme-gold;

$group-buttons-blue: #309dd8;

$footer-height: 100px;
$navbar-height: 100px;
$navbar-bottom: 100px;

$app-max-width: 1440px;

$xs-screen-up: 768px !default;
$sm-screen-up: 769px !default;
$md-screen-up: 993px !default;
$lg-screen-up: 1201px !default;

$xs-screen: 767px !default;
$sm-screen: 768px !default;
$md-screen: 992px !default;
$lg-screen: 1200px !default;

$md-and-up: "only screen and (min-width : #{$sm-screen-up})" !default;
$lg-and-up: "only screen and (min-width : #{$md-screen-up})" !default;
$extra-lg-and-up: "only screen and (min-width : #{$lg-screen-up})" !default;
$xs-only: "only screen and (max-width : #{$xs-screen})" !default;
$sm-and-down: "only screen and (max-width : #{$sm-screen})" !default;
$md-and-down: "only screen and (max-width : #{$md-screen})" !default;
$lg-and-down: "only screen and (max-width : #{$lg-screen})" !default;
$md-only: "only screen and (min-width : #{$sm-screen-up}) and (max-width : #{$md-screen})" !default;

%home-radial {
  background: $theme-color-super-dark;
  background: radial-gradient(circle, rgba($theme-color-super-dark, 0.8) 0%, rgba($theme-color-super-dark, 1) 70%);
}

$slick-duration: 0.2s;
$transition-speed: 0.25s;
$theme-font-xxlarge: 4rem;
$theme-font-xlarge: 3.5rem;
$theme-font-large: 3rem;
$theme-font-medium: 1.5rem;
$theme-font-normal: 1rem;
$theme-font-small: 0.9rem;
$theme-font-xsmall: 0.8rem;

%theme-font {
  font-family: 'Montserrat', sans-serif !important;
}

%cta-button {
  border: 0;
  background-color: $theme-color;
  border-radius: 5px;
  color: white;
  display: inline-block;
  box-sizing: content-box;
  margin: 10px;
  min-width: 50px;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none !important;
  box-shadow: 2px 2px 4px rgba($theme-black, 0.1);
  transition: all $transition-speed;

  &:hover {
    transition: all $transition-speed;
    color: white;
    box-shadow: 4px 4px 8px rgba($theme-black, 0.1);
  }
}

.color-success {
  color: $theme-success;
}

.color-danger {
  color: $theme-danger;
}

.boxxed {
  border: 0;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
}

$navbar-animation-duration: 0.25s;

.btn-primary {
  background-color: $theme-color-dark;
}

.bg-primary {
  background-color: $theme-color-dark !important;
}

.btn {
  font-size: 1.2rem;
  border-radius: 2px;
}

.table-pagination {
  display: inline-block;
  li {
    display: inline;
    text-decoration: none;
    a {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      margin-right: 0 !important;
      line-height: 1.42857143;
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
      &:hover {
        background-color: #ddd;
        border: 1px solid #ddd;
        color: #337ab7;
      }
    }
    &.active {
      a {
        background-color: $theme-color-dark !important;
        border-color: $theme-color-dark !important;
      }
    }
    &.disabled {
      a {
        color: #777;
        cursor: not-allowed !important;
        &:hover {
          background-color: white;
          border: 1px solid #ddd;
          color: #777;
        }
      }
    }
  }
}

.chalice-label {
  font-size: $theme-font-small;
  font-weight: 400;
}


.slider {
  margin-bottom: 35px;
  margin-left: 0;
  margin-right: 0;


  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }

  .slick-prev, .slick-next {
    z-index: 10;
    border-radius: 2px 0 0 2px;
    height: 100px;
    width: 40px;
    margin: auto 0;
    opacity: 0;
    transition: opacity $slick-duration;

    &:before {
      color: $theme-black;
    }
  }

  &:hover {
    .slick-prev, .slick-next {
      opacity: 1;
      background-color: rgba($theme-background, 0.75);
      transition: opacity $slick-duration;
    }
  }

  .slick-prev {
    left: 0px;

    &:before {
      font-family: 'FontAwesome';
      content: '\f053';
    }
  }

  .slick-next {
    right: 0px;

    &:before {
      font-family: 'FontAwesome';
      content: '\f054';
    }
  }
}

.carousel-tile {
  outline: none;

  &:focus {
    outline: none;
  }
}

.slider-home {
  margin-bottom: 35px;
  margin-left: 0;
  margin-right: 0;

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }

  .slick-prev,
  .slick-next {
    z-index: 100;
    border-radius: 2px 0 0 2px;
    height: 100px;
    width: 40px;
    opacity: 1;
    transition: opacity 0.2s;

    &::before {
      color: $theme-black;
    }
  }

  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 0.9;
    }
  }

  .slick-prev {
    left: -60px;

    &::before {
      font-family: 'FontAwesome';
      content: '\f053';
    }

    @media screen and (max-width: 768px) {
      display: none;
      right: 0;

      &::before {
        font-size: 0;
        content: '';
      }
    }
  }

  .slick-next {
    right: -60px;

    &::before {
      right: 0;
      font-family: 'FontAwesome';
      content: '\f054';
    }

    @media screen and (max-width: 768px) {
      display: none;
      right: 0;

      &::before {
        font-size: 0;
        content: '';
      }
    }
  }
}

.row-ptb {
  padding: 20px 0;
}

.Toastify {
    position: absolute;
    z-index: 100002;
}

#mainLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#body {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
