.signup-page {
  @extend %theme-font;

  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
  }

  .modal-close {
    @extend %cta-button;

    background-color: $theme-white;
    color: $theme-black;
    padding: 10px 20px !important;
    border: $theme-black solid 1px;

    &:hover {
      color: $theme-black;
    }
  }

  .registration-container {
    h2 {
      font-size: $theme-font-medium !important;
      margin-bottom: 10px !important;
    }
  }

  .signup-complete {
    text-align: center;
    padding: 20px;
    border-bottom: $theme-light-grey solid 1px;

    i {
      font-size: 70px;
      color: $theme-color-dark;
    }
  }

  h1 {
    font-size: $theme-font-large;
  }

  h1,
  h2,
  h3 {
    text-align: center;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
  }

  h3 {
    font-size: 30px;
  }

  section {
    padding: 0;
    width: 100%;
  }

  .steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;

    .step {
      padding: 10px 0;
      margin: 0 30px;

      h2 {
        font-size: $theme-font-medium;
        color: $theme-black;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }

    .active {
      border-bottom: $theme-gold solid 5px;
    }
  }

  .signup-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
    padding: 10px;
    border-top: $theme-super-light-grey solid 1px;
    font-size: $theme-font-small;

    a {
      padding: 0 20px;
    }
  }

  .row {
    margin-bottom: 5px;
  }

  .main-login-container {
    background-color: #fff;
    border-radius: 2px;
    border: 0;
    width: 350px;
    padding: 20px;

    .logo-wrapper {
      padding: 20px;
      margin: 0 auto;
      text-align: center;
      border-bottom: $theme-light-grey solid 1px;

      img {
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .error-template {
    padding: 20px 0;
  }

  .error-template .error-details {
    padding: 10px 0 40px 0;
  }

  .success {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 auto;

    @media #{$md-and-down} {
      width: 100%;
    }

    h1 {
      margin-bottom: 20px !important;
    }

    .signup-success-content {
      h2 {
        padding: 10px 0;
        font-size: $theme-font-medium;
        font-weight: 500;
      }
      p {
        padding: 10px 0;
      }
    }
  }
}
