.bm-burger-button {
}

.bm-burger-bars {
}

.bm-burger-bars-hover {
}

.bm-cross-button {
}

.bm-cross {
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 90px !important;
}

/* General sidebar styles */
.bm-menu {
  background: $theme-background;
  padding: 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  height: auto !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.hamburger {
  outline: none;
  color: $theme-black;
  height: 55px;
  width: 55px;
  margin: 5px 10px;
  margin-right: 15px;
}

.navbar-nav.nav-slideout > li > a {
  background-color: transparent !important;
  border: transparent solid 0;
  border-left-width: 10px;

  &.nav-join {
    border-radius: 20px;
    background-color: $theme-gold !important;
    display: inline-block;
    color: #fff !important;
    text-transform: uppercase;
  }
}

.navbar-nav.nav-slideout > li.active a {
  background-color: #fff !important;
  border: rgba(0, 113, 206, 0.5) solid 0;
  border-left-width: 10px;
}

.header-logo {
  height: 50px;
  width: auto;
  margin: 2.5px 0;
  margin-right: 15px;

  @media screen and (max-width: 768px) {
    margin-left: 2.5px;
  }
}

.divider {
  background-color: #ccc;
  height: 1px;
}
