
.progess-container {
  width: 100%;
  margin: 30px auto;
  max-width: 600px;
}

.progressbar {
  height: 60px;
  counter-reset: step;
  padding-left: 0;

  li {
    list-style-type: none;
    width: 33%;
    float: left;
    font-size: 1.1rem;
    position: relative;
    text-align: center;
    color: $theme-black;
    font-weight: 400;

    &::before {
      width: 40px;
      height: 40px;
      content: counter(step);
      counter-increment: step;
      line-height: 34px;
      border: 3px solid #d4d4d4;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
      color: #ddd;
      z-index: 999 !important;
      position: relative;
      font-weight: 600;
    }

    &::after {
      width: 100%;
      height: 3px;
      content: '';
      position: absolute;
      background-color: #d4d4d4;
      top: 18px;
      left: -50%;
      // z-index: -1;
    }

    &:first-child::after {
      content: none;
    }

    &.active {
      // color: green;
    }

    &.active::before {
      border-color: #5cb85c;
      background-color: #5cb85c;
      color: white;
      font-weight: 600;
    }

    &.active-current::before {
      border-color: #5cb85c;
      // background-color: #5cb85c;
      color: #5cb85c;
      font-weight: 600;
    }

    &.active, &.active-current {
      &::after {
      background-color: #5cb85c;
    }
    }
  }
}


.progess-container-calc {
  // width: 100%;
  width: 270px;
  margin: 0 auto;
  // max-width: 600px;
}

.progressbar-calc {
  height: 60px;
  counter-reset: step;
  padding-left: 0;
  margin: 0 auto;
  display: flex;


  li {
    list-style-type: none;
    width: 30px;
    float: left;
    font-size: .6rem;
    position: relative;
    text-align: center;
    color: $theme-black;
    font-weight: 300;

    &::before {
      width: 18px;
      height: 18px;
      content: counter(step);
      counter-increment: step;
      line-height: 14px;
      border: 2px solid #d4d4d4;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
      color: #ddd;
      z-index: 999 !important;
      position: relative;
      font-weight: 600;
    }

    &::after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #d4d4d4;
      top: 9px;
      left: -50%;
      // z-index: -1;
    }

    &:first-child::after {
      transition: all 1s;
      content: none;
    }

    &.active {
      transition: all 1s;
      // color: green;
    }

    &.active::before {
      transition: all 1s;
      border-color: lighten($theme-color, 10%);
      background-color: lighten($theme-color, 10%);
      color: white;
      font-weight: 600;
    }

    &.active-current::before {
      transition: all 1s;
      border-color: lighten($theme-color, 10%);
      background-color: white;
      color: lighten($theme-color, 10%);
      font-weight: 600;
      // color: lighten($theme-color, 10%);
    }

    &.active, &.active-current {
      &::after {
        transition: all 1s;
        // color: lighten($theme-color, 10%);
      // background-color: lighten($theme-color, 10%);
      // color: white;
      // color: lighten($theme-color, 10%);
      }
    }
    &.active {
      &::after {
        transition: all 1s;
        background-color: lighten($theme-color, 10%);
      }
    }
  }
}
