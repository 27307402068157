%block-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
    max-width: 100%;
    width: 750px;
  }
  @media (min-width: 992px) {
    max-width: 100%;
    width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 100%;
    width: 1170px;
  }
}

$min-height: 400px;

.container-fluid {
  @extend %block-container;
}

.action-btn-explore {
  @extend %cta-button;

  background-color: $theme-white;
  color: $theme-black;
  font-size: $theme-font-normal;
  border: #e6e6e6 solid 1px;

  &:hover {
    transform: scale(1.02);
    color: $theme-black;
  }
}

.action-btn-gld {
  @extend %cta-button;

  background-color: $theme-gold;
  color: $theme-white;
  font-size: $theme-font-normal;
  border: $theme-gold solid 1px;

  &:disabled {
    background-color: #ccc;
    &:hover {
      transform: none;
    }
  }

  &:hover {
    transform: scale(1.02);
  }
}

.action-btn-blue {
  @extend %cta-button;

  background-color: $theme-color;
  color: $theme-white;
  font-size: $theme-font-normal;

  &:hover {
    transform: scale(1.02);
  }

  &.in-table {
    padding: 5px;
    font-size: 1em;
    margin: 0;
  }

  &.shrink-md {
    margin-right: 0;
    @media #{$md-and-down} {
      font-size: 0.9em;
      padding: 0.5em;
    }
  }
}

.action-btn-danger {
  @extend %cta-button;

  background-color: $theme-danger;
  color: $theme-white;
  font-size: $theme-font-normal;

  &:hover {
    transform: scale(1.02);
  }

  &.in-table {
    padding: 5px;
    font-size: 1em;
    margin: 0;
  }
}

.action-btn-grey {
  @extend %cta-button;

  background-color: $theme-grey !important;
  color: $theme-white !important;
  font-size: $theme-font-normal !important;

  &:hover {
    transform: scale(1.02);
  }
}

.admin-navbar {
  .navbar-content {
    width: 100%;
    max-width: 100%;
    padding: 20px 20px !important;
  }
}

.home-navbar {
  background-color: $theme-white;
  position: fixed;
  z-index: 1040;
  top: 0;
  width: 100%;
  transition: all 0.25s;

  .navbar-content {
    @extend %block-container;

    align-items: center;
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;

    span {
      padding: 0 20px;
    }

    .navbar-link-strong {
      font-weight: 700;
    }

    .navbar-home-left {
      display: flex;
      align-items: center;
    }

    .navbar-home-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .flex-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .header-logo {
      height: var(--chalice-navbar-height);
      width: auto;
      margin: 0 15px 0 0;
    }

    .header-logo-enterprise {
      height: var(--chalice-navbar-height);
      width: auto;
      margin: 0 15px 0 0;
      // padding-left: 15px;
    }

    .nav-btn {
      @extend %cta-button;
    }

    .nav-join {
      @extend %cta-button;

      background-color: $theme-gold;
      margin-top: 5px;
      margin-bottom: 5px;
      transition: all 0.25s;
    }

    .contact-us {
      font-weight: 700 !important;
    }
  }
}

.navbar-stack {
  display: flex;
  flex-direction: column;
  align-items: center;

  small {
    font-size: $theme-font-small;

    a {
      font-size: $theme-font-small;
    }
  }
}

.navbar-bland-cta {
  .nav-join {
    @extend %cta-button;

    background-color: $theme-white !important;
    color: $theme-black !important;
    border: rgba($theme-black, 0.5) solid 1px !important;
    transition: all 0.25s;
  }
}

.navbar-scroll {
  position: absolute;
  box-shadow: none;
  transition: all 0.25s;
}

%home-block {
  width: 100%;
  background-color: $theme-white;
  padding: 0 0 20px 0;

  h1 {
    font-size: $theme-font-xxlarge;
    font-weight: 500;

    span {
      font-weight: 300;
    }

    @media #{$sm-and-down} {
      font-size: $theme-font-xlarge;
    }
  }

  h2 {
    font-size: $theme-font-xlarge;
    font-weight: 300;
    text-align: center;
    padding: 20px 0 20px 0;

    @media #{$md-and-down} {
      font-size: $theme-font-large;
    }
  }

  .content {
    @extend %block-container;

    .action-row {
      margin: 20px 0;
      display: flex;

      .after-dots {
        margin-top: 40px;
      }

      @media #{$md-and-down} {
        flex-direction: column;
      }
    }

    .action-btn {
      @extend %cta-button;

      font-size: $theme-font-normal;
    }

    .description {
      font-size: $theme-font-normal;
      font-weight: 400;

      ul {
        padding: 5px 0;
        margin-bottom: 0;
        font-weight: 300;

        li {
          margin-left: 30px;
          font-weight: 700;
          line-height: 2rem;
          // padding-left: 10px;
          // font-weight: 3,00;
        }
      }

      .secondary-header {
        font-weight: 400;
        font-size: $theme-font-medium;
        margin: 0 0 20px 0;
      }

      .marketplace-btn-wrapper {
        text-align: center;
        // margin: 40px auto;
      }

      .marketplace-btn {
        @extend %cta-button;

        display: inline-block;
        padding: 10px 20px;

        &:active {
          color: white;
          text-decoration: none !important;
        }
      }
    }
  }
}

.home-wrapper {
  background-color: $theme-white;

  .banner-block {
    @extend %home-block;

    padding: 50px 0;
    max-width: 850px;
    margin: 0 auto;
  }

  .intro-block {
    background: #fff;
  }

  .sideimage-block {
    @extend %home-block;

    padding: 100px 0;

    h2 {
      font-weight: 500;
      font-size: 2rem !important;
      text-align: left;
      margin: 0;

      @media #{$md-and-down} {
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media #{$md-and-down} {
        flex-direction: column-reverse;
        justify-content: center;
      }

      ul {
        margin: 0 0 20px 0;
        padding-left: 20px;
      }
    }

    .left {
      width: 50%;
      padding-right: 50px;

      & > div {
        padding-bottom: 20px;
      }

      .action-row {
        @media #{$md-and-down} {
          flex-direction: row;
          // justify-content: center;
        }
      }

      @media #{$md-and-down} {
        width: 100%;
        padding: 50px 0;
      }
    }

    .right {
      padding-left: 50px;
      width: 50%;

      @media #{$md-and-down} {
        width: 100%;
        padding: 0;
      }
    }

    img {
      width: 100%;
      height: auto;
      transition: all 0.25s;
    }
  }

  .pillar-block {
    @extend %home-block;

    background-color: $theme-light-grey;
    padding: 100px 0;

    h2,
    h3,
    h4,
    p {
      text-align: center;
      margin: 10px 0;
    }

    h2 {
      font-weight: 500;
    }

    h3 {
      font-weight: 400;
    }

    h4 {
      margin: 20px 0 10px 0;
    }

    .pillars {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 50px;

      @media #{$md-and-down} {
        flex-wrap: wrap;
      }
    }

    .pillar {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      background-color: $theme-white;
      padding: 20px;
      margin: 5px;
      max-width: 25%;
      color: $theme-black;

      @media #{$md-and-down} {
        max-width: 45%;
      }

      @media #{$xs-only} {
        max-width: 100%;
      }

      &:hover {
        text-decoration: none;

        img {
          border-radius: 0%;
          transition: all 0.25s;
        }
      }

      .pillar-image {
        margin: 0 auto;
        text-align: center;
        flex: 0 1 auto;
      }

      h4,
      p {
        flex: 0 1 auto;
      }

      img {
        border-radius: 50%;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        height: auto;
        transition: all 0.25s;
      }
    }
  }

  .hero-block {
    @extend %home-block;

    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    min-height: 90vh;

    .logo-block {
      height: 200px;

      @media #{$sm-and-down} {
        height: 150px;
      }

      img {
        height: 200px !important;
        width: auto;
      }
    }

    .content {
      position: relative;
      z-index: 10;
      padding: 50px 0;
      margin: 0 auto;
      text-align: center;
      max-width: 800px;
    }

    .background {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;

      img {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
        opacity: 0.05;

        @media #{$md-and-down} {
          padding-top: 120px;
          width: auto;
          height: 100%;
        }
      }
    }

    .action-row {
      margin: 0 auto !important;
    }

    h1 {
      font-size: $theme-font-xxlarge !important;
      font-weight: 500;

      @media #{$md-and-down} {
        font-size: 3rem !important;
      }

      span {
        font-weight: 300;
      }
    }

    .secondary-header {
      font-size: $theme-font-medium !important;

      @media #{$md-and-down} {
        font-size: $theme-font-normal !important;
      }
    }

    .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }

  /* Hacked in here until we port everything over to styled-componets */
  .smallhero-white {
    color: #fff !important;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);

    h1,
    h2,
    h3,
    h4 {
      color: #fff !important;
      text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
    }
  }

  .smallhero-block {
    @extend %home-block;

    position: relative;
    margin: 0;
    padding: 50px 0 100px 0;
    display: flex;
    align-items: center;

    .content {
      position: relative;
      z-index: 10;
      padding: 50px 0;
      margin: 0 auto;
    }

    .action-row {
      margin: 20px auto !important;
    }

    .background {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center top;

        @media #{$md-and-down} {
          width: auto !important;
          height: 100% !important;
        }
      }
    }

    h1 {
      font-size: $theme-font-xlarge !important;
      font-weight: 500;

      @media #{$md-and-down} {
        font-size: 3rem !important;
      }

      span {
        font-weight: 300;
      }
    }

    .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      & > div {
        flex: 1 0 50%;
        padding-right: 10px;
      }

      @media screen and (max-width: 992px) {
        width: 100%;
        padding: 0 10px;

        & > div {
          flex: 1 0 100%;
          padding-right: 10px;
        }
      }
    }
  }

  .intro-block,
  .enterprise-block {
    @extend %home-block;

    position: relative;
    margin: 195px 0 100px 0;
    display: flex;

    @media #{$md-and-down} {
      // padding-top: 120px;
    }

    .content {
      position: relative;
      z-index: 10;
      padding: 50px 0;
    }

    .background {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;

      img {
        position: absolute;
        width: 100%;
        height: auto;
        object-position: center bottom;
        opacity: 0.1;
      }
    }

    h1 {
      font-size: $theme-font-xxlarge !important;
      font-weight: 500;

      @media #{$md-and-down} {
        font-size: 4rem !important;
      }

      span {
        font-weight: 300;
      }
    }

    .content-wrapper {
      width: 75%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }

  .spotlight-block {
    @extend %home-block;

    padding: 50px 0;

    @media #{$md-and-down} {
      padding: 20px 0;
    }

    .content {
      h1 {
        font-size: 2rem;
      }
    }

    .spotlight-btn {
      @extend %cta-button;

      font-size: 1rem;
      transition: all 0.25s;

      &:hover {
        transition: all 0.25s;
        transform: scale(1.05);
      }
    }

    .spotlight-container {
      display: flex;
      flex-direction: row wrap;
      justify-content: space-between;

      @media #{$md-and-down} {
        flex-direction: column;
      }
    }

    .spotlight {
      border-radius: 2px;
      border: $theme-light-grey solid 2px;
      display: flex;
      flex-direction: row;
      margin: 5px;
      padding: 10px;
      width: 33%;

      @media #{$md-and-down} {
        width: 100%;
      }

      .spotlight-image {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-right: 5px;

        img {
          height: 150px;
          width: 150px;
        }
      }

      .spotlight-content {
        width: 60%;
        display: flex;
        flex-direction: column;
        height: 100%;

        .spotlight-title {
          flex: 0 0 auto;
          font-size: 1.3rem;
          font-weight: 800;
          text-align: center;
        }

        .spotlight-subtitle {
          color: $theme-color;
          flex: 0 0 auto;
          font-size: 1rem;
          font-weight: 600;
          padding: 5px 0;
          text-align: center;
        }

        .spotlight-link {
          flex: 1 1 auto;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          a {
            margin: 0;
          }
        }
      }
    }
  }

  .enterprise-block {
    display: flex;
    align-items: center;
    background-image: url(/assets/images/home/exchange-angle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .content-wrapper {
      width: 50%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }

  .exchange-block {
    @extend %home-block;

    padding-top: 70px;
    position: relative;
    background-image: url(/assets/images/home/exchange-angle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .tile-wrapper {
      width: 100% !important;
    }

    .tile-name {
      font-size: 1em !important;
    }

    .tile-description {
      font-size: 0.9em !important;
    }

    .action-row {
      justify-content: left !important;

      @media screen and (max-width: 768px) {
        justify-content: center !important;
      }
    }

    .next-row {
      display: flex;
      justify-content: left;
      align-content: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        justify-content: center !important;
      }

      a {
        background-image: url(/assets/images/home/down-arrow.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        margin: 0;
        border: 0;
        vertical-align: top;
        font-size: 1.3em;
        padding: 40px 45px;
        text-align: center;
        color: $theme-white;
        font-weight: 600;
        height: 250px;
        width: 250px;
        transition: opacity 0.25s;
        text-decoration: none;

        &:hover {
          text-decoration: none;
          opacity: 0.75;
          transition: opacity 0.25s;
        }
      }
    }

    .content {
      z-index: 1000;
    }

    .intro-description {
      font-size: 1.3rem;
      font-weight: 300px;
    }

    .exchange-container {
      padding: 0 300px;
    }

    h1 {
      padding: 40px 0 20px 0;
      font-size: 4rem;
      font-weight: 800;
    }
  }

  .calculator-block {
    @extend %home-block;

    min-height: $min-height;
    padding-bottom: 50px;
    margin: 50px 0;
    background-image: url(/assets/images/home/calculator-angle.png);
    background-size: 100% 100%;
    background-position: bottom;
    background-repeat: no-repeat;

    h1 {
      padding: 60px 0 0 0;
      font-size: 4rem;
      font-weight: 800;
      text-align: center;
    }

    .content-wrapper {
      width: 100%;
      text-align: center;
    }
  }

  .appointment-block {
    @extend %home-block;
    background-color: #f7f8fa;
    padding: 150px 0;
    text-align: center;
  }

  .community-block {
    @extend %home-block;

    min-height: $min-height;
    margin-top: 50px;
    padding-bottom: 50px;
    background-image: url(/assets/images/home/community-angle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .archetype-block {
    @extend %home-block;

    margin-top: 50px;

    h1 {
      text-align: center;
    }

    .secondary-header {
      text-align: center;
    }

    .third-header {
      text-align: center;
      padding-bottom: 20px;
    }

    .archetype-selector {
      align-items: center;

      .fa-chevron-up {
        padding: 3px 3px 10px 3px;
        cursor: pointer;
        position: absolute;
        color: white;
        margin: 0 auto;
        left: calc(100% / 2 + 5px);
        transform: scale(4, 1.5);
      }

      .fa-chevron-down {
        position: absolute;
        color: white;
        margin: 0 auto;
        left: calc(100% / 2 + 5px);
        transform: scale(4, 1.5);
        padding: 3px;
        cursor: pointer;
      }

      .secondary-header {
        font-weight: 600 !important;
        text-align: center;
        padding: 10px;
        margin-bottom: 0 !important;
      }

      .archetype-option {
        padding: 10px 3px 3px 3px;
      }

      .slider-container {
        overflow: hidden;
        height: calc(100% - 115px);
        margin: 25px 0 0 0;
      }

      .slick-slider {
        cursor: pointer;
        top: 25px;
        font-weight: 700;
        overflow: visible;
      }

      .slick-list {
        overflow: visible;
      }

      .slick-track {
        overflow: visible;
      }

      .slick-slide {
        font-size: 1rem;
        text-align: center;
        opacity: 0.6;
      }

      .slick-current {
        opacity: 1 !important;
        font-weight: 1000;
      }

      color: white;
      height: 310px;
      background-color: $theme-color;
    }

    .archetype-description-box {
      height: 310px;
      background-color: rgba($theme-gold, 0.3);

      .archetype-description {
        font-size: 1rem;
        font-weight: 700;
        padding: 50px 30px 0 30px;
        text-align: center;
      }

      .slider-container {
        padding-top: 60px;
        height: 215px;
      }
    }
  }

  .video-success-block {
    @extend %home-block;

    min-height: 500px;
    padding: 50px 0;
    background-color: #fff;
    background-image: url(/assets/images/home/community-angle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;

    .content {
      display: flex;
      flex-flow: wrap;
      flex-direction: row;

      .video-block {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        @media #{$md-and-down} {
          width: 100%;
        }
      }

      .video-container {
        padding: 10px;
        background-color: rgba($theme-color, 0.25);
        border-radius: 5px;
        height: 300px;
        width: 518px;
      }

      @media #{$xs-only} {
        padding-top: 50px;

        .video-container {
          width: 90%;
          height: auto;
        }
      }
    }
  }

  .success-block {
    @extend %home-block;

    min-height: 500px;
    padding: 50px 0;
    background-color: rgba($theme-color, 0.25);
    background-image: url(/assets/images/home/calculator-angle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .content {
      z-index: 100;
      color: $theme-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .success-container {
      max-width: 95%;
      padding: 0;

      .success-card {
        color: $theme-black;
        height: 220px;

        @media screen and (max-width: 1100px) {
          height: 250px;
        }

        @media screen and (max-width: 992px) {
          height: 280px;
        }

        @media screen and (max-width: 768px) {
          height: 250px;
        }

        @media screen and (max-width: 600px) {
          height: 300px;
        }

        margin-top: 40px;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .success-left {
          width: 33%;
          flex: 1 1 auto;
          position: relative;

          @media #{$md-and-down} {
            width: 45%;
          }

          img {
            position: absolute;
            bottom: 0;
            height: 250px;
            width: auto;
            margin: 0 auto;
            display: block;
          }
        }

        .success-right {
          width: 66%;
          padding: 20px;

          @media #{$md-and-down} {
            width: 55%;
          }

          .testimonial {
            font-weight: 400;
            font-size: 1.5rem;
          }
        }

        @media screen and (max-width: 768px) {
          flex: 1 0 100%;

          .success-left {
            width: 0%;
            display: none;
          }

          .success-right {
            width: 100%;
            padding: 20px;

            .testimonial {
              font-weight: 400;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  .partner-block {
    @extend %home-block;

    padding: 50px 0 75px 0;

    h2 {
      padding: 20px 0;
      text-align: center;
    }

    .partner-slider {
      @extend %block-container;

      img {
        margin: 0 auto;
        height: 100px;
        width: 100px;
      }

      .partner-name {
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        padding: 5px 0;
      }
    }

    .partner-container {
      padding: 30px 0 25px 0;
      background-color: $theme-super-light-grey;
    }
  }
}

.events-wrapper {
  background-color: $theme-white;
  padding-top: 150px;

  .inquiries {
    padding-top: 10px;
    text-align: center;

    a {
      font-weight: 600;
    }
  }

  .sponsors-block {
    margin-top: 10px;
    background-color: #eee;
    padding: 10px 0;

    .sponsors-wrapper {
      @extend %block-container;

      img {
        opacity: 0.75;
        height: 50px;
        width: auto;
      }
    }
  }

  .events-nav-block {
    @extend %home-block;

    background-color: rgba($theme-color, 0.15);
    padding: 20px 0;
    margin-bottom: 20px;

    .header-links {
      h1 {
        display: inline;
        color: rgba($theme-color, 0.75);

        a {
          color: rgba($theme-color, 0.75);
          text-decoration: none;
          margin-right: 40px;
          border-bottom: rgba($theme-black, 0) solid 4px;
          transition: all 0.2s;

          &:hover {
            color: $theme-black;
            transition: all 0.2s;
          }

          &.active {
            color: $theme-black;
            border-bottom: $theme-black solid 4px;
            transition: all 0.2s;
          }
        }
      }
    }
  }

  .events-block,
  .press-block,
  .news-block {
    @extend %home-block;

    .col-70 {
      padding: 5px !important;
    }

    h1 {
      margin: 0;
      padding: 0;

      @media #{$md-and-down} {
        text-align: center;
      }
    }

    .item-row {
      padding-bottom: 30px;

      .name {
        color: $theme-color !important;
        font-weight: 700;
        font-size: 1.3rem;
        padding-bottom: 10px;
      }

      .short-description {
        font-weight: 400;
        font-size: 1.1rem;
      }
    }

    .item-link {
      background-color: transparent;
      transition: background-color 0.2s;
      color: black;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: rgba($theme-super-light-grey, 0.5);
        transition: background-color 0.2s;
      }
    }
  }

  .press-block {
    padding-top: 50px;
  }
}

.about-us-wrapper {
  background-color: $theme-white;

  .intro-block {
    @extend %home-block;

    padding-top: 150px;
    background-image: url(/assets/images/home/bg-about-us.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    .content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .right {
        padding-left: 20px;

        .video-container {
          padding: 10px;
          background-color: $theme-white;
          border-radius: 5px;
          height: 300px;
          width: 518px;
        }

        @media #{$sm-and-down} {
          padding-top: 50px;
        }

        @media #{$xs-only} {
          padding-top: 50px;

          .video-container {
            width: 90%;
            height: auto;
          }
        }
      }

      padding-bottom: 50px;
    }

    .action-row {
    }

    .description {
      font-size: 1.3rem;
      font-weight: 400;
    }
  }

  .subheader-block {
    @extend %home-block;

    .content-wrapper {
      padding: 50px 0;
    }
  }

  .advisory-board-block {
    @extend %home-block;

    background-color: rgba($theme-color, 0.1);
    background-image: url(/assets/images/home/calculator-angle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .content-wrapper {
      padding: 50px 0;
    }

    h1 {
      text-align: center;
    }

    img {
      max-height: 300px;
    }

    .board-picture {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 5px;
      }
    }

    .description {
      padding: 20px 0;
    }

    .board-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0;

      li {
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 33%;
        margin: 0;
        padding: 5px 20px 5px 0;
        font-weight: 600;

        @media #{$md-and-down} {
          width: 50%;
        }

        @media #{$xs-only} {
          width: 100%;
        }

        div {
          font-size: 0.9rem;
          font-weight: 300;
        }
      }
    }
  }

  .leadership-block {
    @extend %home-block;

    .content-wrapper {
      padding: 50px 0 20px 0;
    }

    h1 {
      text-align: center;
    }

    .profile-container {
      position: relative;
      margin-top: 20px;
      padding: 20px 0;
      clear: both;
      display: flex;
      flex-flow: wrap;
      flex-direction: row;
      justify-content: center;

      & > div {
        margin-bottom: 40px;
        padding: 0 15px;
        height: 170px;
        width: 50%;
        float: left;

        @media #{$md-and-down} {
          width: 100%;
        }
      }
    }

    .profile-mb-0 {
      padding-bottom: 0;

      & > div {
        margin-bottom: 0;
      }
    }

    .profile {
      height: 170px;
      width: 100%;

      .profile-wrapper {
        position: relative;
        width: 100%;
        z-index: 999;
        display: flex;
        flex-direction: column;
      }

      .profile-absolute {
        position: absolute;
        height: 100%;
        z-index: 1000;
      }

      .profile-header {
        height: 170px;
        position: relative;
        display: flex;
        flex-direction: row;
        flex: 0 1 auto;
        background-image: url(/assets/images/home/bg-aboutus-photo.jpg);
        background-size: cover;
        background-repeat: no-repeat;

        .profile-info {
          position: absolute;
          left: 200px;
          right: 0;
          top: 0;
          bottom: 0;
          width: 60%;
          padding: 10px;

          @media #{$xs-only} {
            left: 0;
            width: 100%;
          }

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h2 {
            color: $theme-black;
            font-weight: 700;
            font-size: 2rem;
            text-align: center;
            margin: 0;
          }

          h3 {
            color: $theme-black;
            font-weight: 400;
            font-size: 1.2rem;
            text-align: center;
            margin: 2px 10px 5px 10px;
          }

          .social {
            margin: 5px;
          }
        }

        .profile-image {
          height: 200px;
          width: auto;
          position: absolute;
          bottom: 0;
          left: 0;

          @media #{$xs-only} {
            display: none;
          }
        }
      }

      .board-header {
        background: rgba($theme-color, 0.1);
        background: radial-gradient(
          ellipse at center,
          rgba($theme-color, 0.4),
          rgba($theme-color, 0.02)
        );

        .profile-info {
          left: 0 !important;
          width: 100% !important;

          h3 {
            max-width: 400px;
          }
        }
      }

      .bio {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        flex: 1 1 auto;
        background-color: $theme-super-light-grey;
        position: relative;

        .content {
          width: 100%;
          padding: 50px 50px;
          overflow-x: hidden;
          overflow-y: scroll;
        }
      }

      .expand-image {
        z-index: 1000;
        height: 50px;
        width: 50px;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;

        img {
          height: 100%;
          width: 100%;
        }

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
}

.cyber-insurance-block {
  @extend %home-block;

  padding: 150px 0 50px 0;
  background-color: $theme-background-grey;

  h1,
  h2 {
    font-size: $theme-font-medium;
    font-weight: 400;
    padding: 20px 0;
  }

  .sidebar {
    position: sticky;
    top: 110px;

    .provided-by {
      padding: 10px;
      color: #333;
    }
  }

  .content-wrapper {
    padding: 10px;
    background-color: white;

    @extend .boxxed;
  }

  .description {
    strong {
      white-space: nowrap;
    }

    p {
      font-size: 1.5rem;
    }
  }

  .row {
    margin-bottom: 5px;
  }

  form {
    padding: 10px;
    background-color: $theme-white;
  }
}

.contact-us-wrapper {
  background-color: $theme-white;

  .contactus-block {
    @extend %home-block;

    padding: 150px 0 50px 0;
    background-image: url(/assets/images/home/bg-about-us.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    .description {
      strong {
        white-space: nowrap;
      }

      p {
        font-size: 1.5rem;
      }
    }

    .row {
      margin-bottom: 5px;
    }

    form {
      @extend .boxxed;

      padding: 10px;
      background-color: $theme-white;
    }
  }
}

.pricing-wrapper {
  @extend %home-block;

  padding-top: 150px;

  &.in-modal {
    background-color: $theme-light-grey;
  }

  @media #{$md-and-down} {
    padding-top: 100px;
  }

  .pricing-block {
    padding: 50px 0 0 0;
    position: relative;

    .content {
      position: relative;
      z-index: 10 !important;
      min-height: 500px;
    }

    .content-wrapper {
      padding-bottom: 0;
    }

    h1 {
      font-size: $theme-font-xlarge;
      color: $theme-black;
      font-weight: 400;
      text-align: center;
      max-width: 900px;
      margin: 0 auto;

      @media #{$md-and-down} {
        font-size: $theme-font-large;
      }
    }

    .skip-link {
      font-size: 1.1rem;
      color: $theme-grey;
      margin-left: 20px;
    }

    .pricing-table {
      display: flex;
      flex-direction: row;
      // justify-content: flex-start;
      justify-content: center;
      flex-flow: wrap;
      padding: 0;
      position: relative;

      .add-on-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;

        @media #{$md-and-down} {
          width: 100%;
        }

        .tier-form {
          width: 100% !important;
        }
      }

      .tier-form {
        // margin: 20px 10px 0 10px;
      }

      .tier {
        display: flex;
        flex-direction: column;
        margin: 20px 10px 20px 10px;
        padding: 20px 10px 0 10px;
        width: 33%;
        max-width: 33%;
        border: $theme-white !important;

        @media #{$lg-and-down} {
          width: 45%;
          max-width: 45%;
          flex-flow: wrap;
        }

        @media #{$md-and-down} {
          width: 100%;
          max-width: 100%;
          flex-flow: wrap;
        }
      }

      .tag-filler {
        margin-top: 40px !important;
      }

      .tier-standard {
        padding: 0 !important;
      }

      .tier-top {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        // box-shadow: 4px 4px 16px rgba(#000, 0.2);
        border: $theme-super-light-grey solid 1px;
        margin: 0 0 20px 0;

        .tier-box {
          display: flex;
          flex: 1 0 auto;
          flex-direction: column;
          background-color: $theme-white;

          .tier-title {
            background-color: $theme-color-super-dark;
            color: $theme-white;
            margin: 0;
            padding: 20px 0;
          }

          h3 {
            text-align: center;
            color: $theme-white;
            font-weight: 500;
            padding: 5px;
            margin: 0;
            font-size: 1rem;
          }

          h2 {
            text-align: center;
            background-color: $theme-color-super-dark;
            color: $theme-white;
            font-weight: 500;
            font-size: 2rem;
            overflow: hidden;
            margin: -5px -5px 0 -5px;
            padding: 10px 5px;
          }

          .features {
            display: none;

            @media #{$md-and-down} {
              display: block;
            }
          }

          .tier-features {
            ul {
              margin: 0;
              padding: 0 0 0 20px;
            }
          }

          .tier-content {
            padding: 5px;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            &.add-ons-content {
              padding: 15px;
              flex-direction: column;
            }

            .add-ons {
              display: flex;
              align-items: center;

              img {
                max-width: 50%;
                height: auto;
                padding-right: 15px;
              }
            }

            .cart-item {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #a4a4a4;

              .cart-item-left {
                display: flex;
                flex-direction: column;
                padding: 5px 0;
              }

              .remove-cart {
                justify-content: flex-end;
              }
            }

            .cart-total {
              font-weight: 500;
              padding: 5px 0;
            }

            .deal-line {
              font-weight: 500;
              margin-top: 10px;
              font-size: 1.1rem;
            }

            .add-row {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .add-on-button {
                border-color: $theme-color-secondary;
                background-color: white;
                padding: 10px 20px;
                border-radius: 5px;
                margin-top: 10px;
                font-weight: 500;
                &:after {
                  content: 'Add';
                }

                &:disabled {
                  background-color: #eee;
                  &:after {
                    content: 'In Cart';
                  }
                }
              }

              .learn-more {
                font-size: 1.1rem;
                display: flex;
                align-items: center;
              }
            }

            .price {
              font-size: $theme-font-medium;
              text-align: center;
              flex: 0 1 auto;
              padding: 20px 0;

              & > div {
                font-size: $theme-font-normal;
              }
            }

            .description {
              flex: 1 1 auto;
              font-size: $theme-font-normal;
            }

            .action-row {
              flex: 0 1 auto;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .tier-bottom {
        padding: 0 0 20px 0;
        margin: 0 10px;
      }
    }

    .pricing-wide {
      justify-content: center;

      .tier-form {
        width: 50%;
        padding: 20px;

        &.in-modal {
          width: 100%;
          padding: 0;
          margin-left: 50px;
          @media #{$sm-and-down} {
            margin-left: 0px;
            margin-top: 50px;
          }
        }

        @media #{$md-and-down} {
          width: 100%;
          max-width: 100%;
          flex-flow: wrap;
        }
      }

      h2 {
        font-size: $theme-font-medium !important;
      }

      @media #{$md-and-down} {
        justify-content: center;
      }

      .form {
        display: flex;
        flex-direction: column;
        margin: 20px 10px 0 10px;
        width: 23%;
        max-width: 23%;
      }

      .tier {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0 !important;
      }

      .tag-filler {
        margin: 0 !important;
      }

      .tier-top {
        width: 100%;

        @media #{$md-and-down} {
        }
      }
    }

    .pricing-center {
      justify-content: center;

      .tier-form {
        width: 50%;

        @media #{$md-and-down} {
          width: 75%;
        }

        @media #{$sm-and-down} {
          width: 100%;
        }
      }
    }
  }

  .pricing-grid-block {
    display: block;
    padding: 50px 10px;
    background-color: $theme-white;

    @media #{$md-and-down} {
      display: none;
    }

    .action-btn-gld {
      min-width: auto !important;
    }

    .pg-sticky {
      position: sticky;
      top: 0;
      background-color: $theme-white;
    }

    .pricing-grid {
      width: 100%;

      th,
      td {
        border: 1px solid $theme-light-grey;
        padding: 10px;
      }

      h2 {
        font-size: $theme-font-medium;
        padding: 0px;
      }

      .pg-tier-row {
        position: sticky;
        top: $navbar-bottom;
      }

      .pg-header {
        font-size: $theme-font-medium;
        background-color: $theme-super-light-grey;
        padding: 10px 10px;
      }

      .pg-title-cell {
        div {
          font-size: $theme-font-xsmall;
          padding: 2px 0 0 10px;
          font-style: italic;
        }

        span {
          float: right;
          color: $theme-grey;

          svg {
            height: 20px;
            width: 20px;
          }
        }
      }

      .pg-cell {
        width: 200px !important;
        text-align: center;
      }

      .yup,
      .nope {
        height: 25px;
        width: 25px;
        margin: 0 auto;

        svg {
          height: 100%;
          width: 100%;
        }
      }
      .yup {
        color: green;
      }
      .nope {
        color: $theme-grey;
      }
    }
  }
}

.compare-plans {
  margin: 20px 0;
  text-align: center;
  font-size: $theme-font-medium;
}

.legal-wrapper {
  background-color: $theme-white;
  padding-top: 120px;

  .legal-block {
    @extend %home-block;

    background-image: none;

    .content-wrapper {
      width: 50%;

      @media #{$md-and-down} {
        width: 100%;
      }
    }

    .panel-body {
      height: auto !important;
    }
  }
}

.slick-center {
  .option {
    opacity: 1 !important;
  }
}

.option {
  opacity: 0.5 !important;
}

.pt-none {
  padding-top: 0 !important;
}

.counter-offset-first {
  padding-top: 40px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}

.counter-offset-second {
  padding-top: 90px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}

.counter-offset-third {
  padding-top: 10px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}

.counter {
}

.counter-inner {
}

.counter-row {
}

.counter-number {
  color: $theme-black;
  font-size: 3.1rem;
  font-weight: 600;
  line-height: 3.1rem;
}

.counter-descriptor {
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
}

.mb-none {
  margin-bottom: 0;
}
.footer-block {
  @extend %block-container;

  padding: 15px 0 15px 0;
  // padding: 30px 0 25px 0;
  background-color: $theme-white;
  color: $theme-black;
  position: relative;

  .footer-mainlist {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 50px 0;

    & > li {
      padding: 0 20px;
      list-style-type: none;
      margin: 0;
      padding: 0;
      flex: 0 0 25%;
      max-width: 25%;

      @media #{$md-and-down} {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 20px;
      }

      @media #{$sm-and-down} {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .footer-sublist {
    margin: 0;
    padding: 10px 0;
    display: flex;
    flex-direction: column;

    li {
      white-space: nowrap;
      list-style-type: none;
      margin: 0;
      padding: 5px 0;
    }

    div {
      padding-bottom: 5px;
    }

    a,
    button {
      border: 0;
      background: transparent;
      color: $theme-black;
      margin: 0;
      padding: 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer-headline {
    font-size: $theme-font-normal;
    font-weight: 500;
  }

  .footer-bottom {
    border: $theme-light-grey solid 0;
    border-top-width: 1px;
    padding: 30px 15px 15px 0;
  }
}

.footer-whitelabel-block {
  @extend %block-container;
}

.footer-whitelabel {
  background-color: $theme-white;
  padding: 50px 0 10px 0;
  color: #333;
  position: relative;

  .phone {
    color: $theme-black;
    text-decoration: none;

    &:hover {
      color: $theme-black;
      text-decoration: underline;
    }
  }

  .footer-image {
    height: 50px;
    width: auto;
    margin: 20px;
  }

  .footer-nav-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;

    .no-margin {
      margin: 0 auto;
    }
  }

  .footer-nav {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      padding: 10px 5px 15px 5px;
    }

    a {
      color: $theme-black;
      margin: 8px 0;
      padding: 1px 0;
      margin-right: 20px;
      text-decoration: none;
      font-weight: 600;
      border-bottom: rgba($theme-black, 0) solid 1px;
      transition: border 0.25s;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        text-align: center;
      }

      &:hover {
        border-bottom: rgba($theme-black, 1) solid 1px;
        transition: border 0.25s;
      }
    }
  }

  .legal {
    padding: 20px 15px 0 0;
    text-align: center;
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      padding: 30px;
    }
  }

  .help-links {
    text-align: center;
    padding: 0 20px;
    margin: 0 auto;
    a {
      color: $theme-black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .left-info {
    padding: 10px;
    list-style-type: none;
    color: black;
    text-align: left;
    margin-bottom: 0;
    li {
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }
}

.phone-emulator {
  display: flex;
  flex-direction: row;
  float: right;

  .phone-control {
    flex: 1 1 auto;
    padding: 92px 0 146px 0;
    max-width: 14px;
  }

  .phone-container {
    // flex: 1 1 auto;
    // float: left;
    position: relative;
    max-width: 383px;

    img {
      max-width: 383px !important;
      width: 100%;
      height: auto !important;
    }

    .phone-screen {
      background-color: $theme-super-light-grey;
      position: absolute;
      top: 15.3% !important;
      left: 22.2% !important;
      right: 9.4% !important;
      bottom: 24.7% !important;
      width: 55.1%;
      padding: 2px;
      max-width: 211px;
      /* border: pink solid 5px; */

      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
}

.tablet-emulator {
  .tablet-container {
    max-width: 450px;
    width: 100%;
    flex: 1 1 auto;
    position: relative;

    .tablet-img {
      max-width: 450px !important;
      width: 100%;
      height: auto !important;
    }

    .tablet-screen {
      background-color: $theme-super-light-grey;
      position: absolute;
      top: 14.6% !important;
      left: 21.7% !important;
      right: 34.67% !important;
      bottom: 23.4% !important;
      width: 55.56%;
      padding: 2px;
      // border: pink solid 1px;

      overflow-x: hidden;
      overflow-y: hidden;

      img {
        height: 100%;
        width: 250px;
      }
    }
  }
}

.flex-grid {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  align-items: center;

  .col-100 {
    width: 100%;
    padding: 10px;
  }

  .col-50 {
    width: 50%;
    padding: 10px;

    @media #{$md-and-down} {
      width: 100%;
    }
  }

  .col-30 {
    width: 30%;
    padding: 10px;

    @media #{$md-and-down} {
      width: 0;
      display: none;
    }
  }

  .col-70 {
    width: 70%;
    padding: 10px;

    @media #{$md-and-down} {
      width: 100%;
    }
  }
}

.smaller-success {
  font-size: 0.9em;
}

.smallest-success {
  font-size: 0.75em;
}

.nav-slideout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .top {
    flex: 1 1 100%;
  }

  .bottom {
    flex: 0 1 100%;
  }

  hr {
    color: $theme-black;
    background-color: $theme-light-grey;
    height: 1px;
  }

  a {
    display: block !important;
    padding: 20px 0;
    font-weight: 500;
    text-align: center;
    color: $theme-black;
    font-size: 1.2rem;
    text-decoration: none;
    background-color: transparent;
    transition: background-color 0.25s;

    &:hover,
    &.active {
      color: $theme-black;
      font-weight: 700;
      background-color: $theme-white;
      text-decoration: none;
      transition: background-color 0.25s;
    }
  }
}

.powered-by-header {
  img {
    height: 70px;
    width: auto;
    margin: 0 0 0 20px;
  }
}

// these need to be refactored out

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-end {
  display: flex;
  align-items: flex-end;
}

.align-items-center {
  display: flex;
  align-items: center;

  &.d-col-small {
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}

.mt-50 {
  @media only screen and (min-width: 767px) {
    margin-top: 70px !important;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 20px;
}

.mb-0 {
  margin-bottom: 0px;
}

.no-free-disclaimer {
  background-color: #022241;
  padding: 20px;
  color: white;
  max-width: 1440px !important;
  width: 85%;
  margin: 0px auto 20px auto;
  font-weight: 500;
  font-size: 16px;
}

.pb-50 {
  padding-bottom: 50px;
}

.platforms-container {
  padding-top: 100px;
  @media only screen and (max-width: 767px) {
    padding-top: 50px;
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 25px;
  }
  .platforms-flex {
    display: flex;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .col-cta {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }

      div {
        display: flex;
        flex-flow: column nowrap;
        width: 33%;
        margin: 0 auto;
        padding: 15px;
        text-align: left;
        @media only screen and (max-width: 767px) {
          width: 100% !important;
          width: auto;
        }
        img {
          margin: 0 auto;
          height: 250 !important;
          @media only screen and (max-width: 990px) {
            height: 220px !important;
            // margin-top: 20px;
          }
        }
        h2 {
          margin-top: 10px;
          padding-bottom: 24px;
          @media only screen and (max-width: 990px) {
            margin-top: 0px;
          }
        }
        p {
          margin-bottom: 15px;
          color: #7c8087;
        }
        a {
          margin-top: auto;
          @media only screen and (max-width: 990px) {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

.largeIcon {
  height: 100px;
  width: 100px;
}

.largeSuccessIcon {
  height: 100px;
  width: 100px;
  color: $theme-black;
}

.introjs-tooltip {
  min-width: 400px !important;

  h2 {
    @extend %theme-font;

    font-weight: 500;
  }

  p {
    @extend %theme-font;
  }
}

.introjs-nextbutton {
}
