$home-blue: rgba(0, 113, 206, 0.5);
$soft-blue: rgba(0, 113, 206, 0.25);
$transition-speed: 0.1s;

#login-page {
  @extend %theme-font;

  height: 100%;

  .flex {
    display: flex;
    flex-direction: row;
  }

  .flex .language,
  .flex .content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex .language {
    padding: 100px;
    width: 50%;
  }

  .upper-case {
    text-transform: uppercase;
  }

  .language .description {
    margin-bottom: 30px;
    font-size: 20px;
    color: #fff;

    strong {
      color: $theme-gold;
      font-weight: 600;
    }
  }

  .flex .content {
    padding: 50px;
    width: 50%;
  }

  .vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    color: #000;
    text-align: center;
  }

  .home-title {
    font-size: 40px;
    font-weight: 300;
    line-height: 1em;
    margin-bottom: 30px;
    color: #fff;

    strong {
      font-weight: 600;
      color: $theme-gold;
    }
  }

  .home-login {
    height: 100%;
    background-color: #fff;

    iframe {
      border: 0 !important;
      overflow: hidden !important;
    }

    .language {
      background-color: $home-blue;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(/assets/images/hero.jpg);
      width: 60%;

      .home-title {
        font-size: calc(40px + 31 * ((100vw - 375px) / 1065));
      }
    }

    .content > div {
      width: 70%;
    }

    .login-title,
    .signup-title {
      font-size: 30px;
      font-weight: 500;
      padding-left: 15px;
    }

    .login-sub,
    .signup-sub {
      font-size: 18px;
      padding-left: 15px;
      margin-bottom: 10px;
    }

    .login-wrapper,
    .signup-wrapper {
      padding: 10px 0;

      button {
        width: 100%;
      }

      .row {
        margin-bottom: 10px;
      }
    }

    .login-wrapper {
      padding: 10px;
    }

    .content {
      background: #fff;
      padding: 0 0;
      width: 40%;
    }
  }
}

.main-login {
  @extend %theme-font;
  padding-top: 150px;

  height: 100%;
  background-color: $theme-white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .navbar-main-login {
    background-color: transparent;
    border: 0;
  }

  h3 {
    text-align: center;
    font-weight: 500;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  & > section {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .row {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
  }
}

.login-modal {
  .logo-wrapper {
    padding: 20px 0;
    margin: 0 auto;
    text-align: center;
    border-bottom: $theme-light-grey solid 1px;
    margin-bottom: 20px;

    img {
      text-align: center;
      margin: 0 auto;
    }
  }
}

.error-template {
  padding: 50px 0 100px 0;
}

.sso-container {
  margin-top: $navbar-height;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container,
.registration-container {
  padding: 0;

  form {
    width: 100%;
  }

  .row {
    margin-bottom: 5px;
  }

  .login-sub {
    padding-top: 5px;
  }
}

.captcha-disclaimer {
  padding-top: 10px;
}
