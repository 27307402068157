.affix {
  width: 20em;
  position: fixed;
  padding: 0;

  @media #{$sm-and-down} {
    width: 30px;
  }

  ul {
    @extend %theme-font;

    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0 0 0 5px;
    margin: 0;
    border: transparent solid 0;
    border-width: 0;
    width: 100%;

    @media #{$sm-and-down} {
      padding: 0;
    }

    &.active {
      border: rgba(0, 113, 206, 0.5) solid 0;
      border-width: 0;
      background-color: #eee;
    }

    button {
      background: transparent;
      border: 0;
      color: #000;
      padding: 30px 10px;
      display: inline-block;
      text-decoration: none;
      text-align: left;
      width: 100%;

      @media #{$sm-and-down} {
        text-align: center;
        font-size: 15px;
        padding: 30px 0;
      }

      &:focus {
        border: 0;
        outline: 1px;
      }
    }
  }
}
