.component-library {
  /*
  h1 {
    font-size: $theme-font-xxlarge;
    color: $theme-black;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 0 20px 0;
  }
  */

  /*
  h2 {
    font-size: $theme-font-large !important;
    color: $theme-black;
    font-weight: 600 !important;
    margin: 0 auto;
    padding: 10px 0 20px 0;
  }
  */

  .partner-background {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    width: 50%;

    .gatsby-image-wrapper {
      height: 100%;
      width: auto;
    }

    @media #{$md-and-down} {
      width: 100%;
      opacity: 0.15;
    }
  }

  .partner-block {
    @extend %home-block;

    padding: 150px 0 50px 0;
    position: relative;

    @media #{$md-and-down} {
      padding: 100px 0 50px 0;
    }

    h1 {
      font-size: $theme-font-xlarge;
      color: $theme-black;
      font-weight: 600;
      margin: 0 auto;
      padding: 10px 0 20px 0;
    }

    .subtitle {
      font-size: $theme-font-xlarge;
      color: $theme-black;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }

    .description {
      font-size: $theme-font-medium;
    }

    .content-wrapper {
      padding: 50px 50px 50px 0;
      max-width: 50%;

      @media #{$md-and-down} {
        padding: 10px;
        max-width: 100%;
      }
    }

    .content {
      color: $theme-black;
      font-size: $theme-font-medium;
      padding: 40px 0;
    }
  }

  .ourpartner-block {
    @extend %home-block;

    padding: 50px 0 100px 0;
    background-color: $theme-white;

    .ourpartner-container {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
    }

    .ourpartner-icon {
      flex: 1 1 250px;
      width: 250px;
      max-width: 250px;
      height: auto;
      margin: 10px 20px;
    }
  }

  .benefits-block, .benefit-details-block {
    @extend %home-block;

    padding: 100px 0 100px 0;

    h2 {
      margin-bottom: 20px;
    }

    .description {
      max-width: 70%;
      margin: 0 auto;
      font-size: $theme-font-medium;

      @media #{$md-and-down} {
        max-width: 100%;
      }
    }

    p {
      padding: 10px 0;
    }

    .partner-form {
      box-shadow: 2px 2px 32px rgba($theme-black, 0.2);
      border-radius: 5px;
      padding: 50px;

      @media #{$sm-and-down} {
        padding: 20px;
      }

      iframe {
        width: 100% !important;
      }

      input {
        background-color: $theme-white !important;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;

      @media #{$md-and-down} {
        flex-direction: column;
      }

      .col {
        width: 50%;
        padding-top: 20px;

        @media #{$md-and-down} {
          width: 100%;
        }
      }
    }
  }

  .benefit-details-block {
    // @extend %grey-radial;

    .content {
      transition: all 0.25s;

      &:hover {
        .description {
          opacity: 0.5;
          transition: all 0.25s;
        }
      }
    }

    .description {
      width: 50%;
      padding: 50px 50px 0 0;
      transition: all 0.25s;

      @media #{$md-and-down} {
        width: 100%;
      }

      strong {
        display: block;
      }

      &:hover {
        opacity: 1 !important;
        transition: all 0.25s;
      }
    }
  }
}
