@import '../../styles/sass/_utils.scss';
@import '../../styles/sass/_helpers.scss';

$tile-radius: 5px;
$border-color: #e6e6e6;

.tile_wrapper {
  padding: 2px 10px 30px 10px;
  width: 33%;
  position: relative;

  @media #{$md-and-down} {
    padding: 2px 10px 30px 10px;
    width: 50%;
  }

  @media #{$xs-only} {
    padding: 2px 0 30px 0;
    width: 100%;
  }

  a {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    background-color: transparent;
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

.tile {
  border-radius: $tile-radius;
  transition: all $transition-speed;
  border: $border-color solid 1px;
  box-shadow: none;
  padding: 10px;

  &:hover {
    transition: all $transition-speed;
    box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.15);

    &.dashboard_border_radius {
      opacity: 1;
      transition: none;
    }
  }

  .tile_title {
    text-decoration: none;
    overflow: hidden;
  }

  .bottom_description {
    flex: 1 1 auto;
    text-overflow: ellipsis;
    padding: 5px;
    margin-bottom: 15px;
  }

  .tile_name {
    padding: 5px 5px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
  }
}

.loading_tile_spacer {
  width: 100%;
  height: 33%;
  min-height: 150px;
  max-height: 275px;

  @media #{$md-and-down} {
    min-height: 250px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    flex: 1 1 auto;
    height: 100%;
  }
}

@mixin gen-background($edge, $middle) {
  background: linear-gradient(90deg, $edge 35%, $middle 50%, $edge 65%);
  background-size: 200% 200%;
  animation: LoadingAnimation 1s ease-in-out infinite;
}

%loading-background {
  @include gen-background(#fafafa, #eee);
}

%loading-background-white {
  @include gen-background(transparent, rgba(#fafafa, 0.2));
}

.loading_text {
  @extend %loading-background;

  font-size: inherit;
  margin-bottom: 5px;
}

@mixin tier-circle($color) {
  height: 15px;
  width: 15px;
  background-color: $color;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
}

.tier_circle_0 {
  @include tier-circle($theme-tier-0);
}

.tier_circle_1 {
  @include tier-circle($theme-tier-1);
}

.tier_circle_2 {
  @include tier-circle($theme-tier-2);
}

.tier_circle_3 {
  @include tier-circle($theme-tier-3);
}

@mixin tier-border($color) {
  border-bottom: $color solid 5px !important;
}

.tier_0_border {
  @include tier-border($theme-tier-0);
}

.tier_1_border {
  @include tier-border($theme-tier-1);
}

.tier_2_border {
  @include tier-border($theme-tier-2);
}

.tier_3_border {
  @include tier-border($theme-tier-3);
}

.pr_none {
  padding-right: 0 !important;
}

.pl_none {
  padding-left: 0 !important;
}

.selected_dot {
  background-color: #0e2240;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 58%;
  margin-left: -50px;
  border: 1px solid #ccc;
  z-index: 99999;
}

.bg {
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    text-align: center;
    margin: 0 auto;
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    padding: 5px;
  }
}
