
a.viewall {
  color: $theme-black;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

#dashboard {
  @extend %theme-font;
  padding: 20px;

  h2 {
    color: #000;
    text-align: left;
    text-transform: none;
    padding: 10px 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 600;
  }

  .group {
    margin-bottom: 30px;
  }

  .dashboard-cards {
    .dashboard-card {
      padding: 0 10px 20px 0;
      height: 100%;
      display: block;
      position: relative;

      text-decoration: none;
      color: $theme-black;

      &:hover {
        text-decoration: none;
      }

      .boxxed {
        padding: 10px 20px 20px 20px;
        background-color: #fff;

        &:hover {
          background-color: rgba($theme-color, 0.05);
          color: $theme-black;
        }
      }

      img {
      }

      h2 {
        font-size: 18px;
        line-height: 22px;
      }

      h3 {
        font-size: 14px;
        color: $theme-grey;
        padding: 0;
        margin: 10px 0;
        margin-top: -10px;

        span::before {
          content: '\0000a0\002022\0000a0'
        }
      }
    }

    .blurred-card {
      filter: blur(0.2rem);
    }

    .blurred {
      background-color: rgba($theme-white, 0.9);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: $theme-font-medium;
      }
    }
  }

  .progress-view {
    @extend .boxxed;
    margin: 0 20px 20px 20px;
    padding: 0;
    background-color: #fff;

    .nav-progress {
      padding: 20px;
      display: flex;
      flex-direction: row;
      border-bottom: #e5e5e5 solid 1px;
    }

    .progress-block {
      button {
        width: 100%;
        text-align: left;

        i.arrow {
          margin-right: 0px;
          margin-left: 10px;
        }
        span {
          display: inline-block;
          width: 90%;
          padding: 5px;
        }
      }
    }

    .progress-inline {
      display: flex;
      flex-direction: row;

      button {
        width: 20%;
        display: flex;
        align-items: center;
        text-align: left;
      }
    }

    .selector {
      color: $theme-grey;
      border: 0;
      cursor: pointer;
      border-bottom: #e5e5e5 solid 1px;
      padding: 15px 10px 15px 10px;

      transition: all 0.25s;
      font-weight: 500;
      border: 0;
      background: $theme-light-grey;

      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 768px) {
        padding: 10px
      }

      i {
        font-size: 20px;
        // margin: 10px;
        transition: all 0.25s;
        display: inline-block;
        margin-right: 10px;
      }

      &:hover,
      &.active {
        color: $theme-black;
        background-color: #fff;
      }

      &:focus,
      &:active {
        border: 0;
        outline: none;
      }

      .complete {
        color: $theme-success !important;
      }
    }

    .progress-panel {
      display: none;
      border: 0;
      /*
      border-top: #e5e5e5 solid 1px;
      border-bottom: #e5e5e5 solid 1px;
      */

      &.active {
        display: block;
      }
    }
  }

  .questionnaire {
    padding: 20px;
    border: 0;
    min-height: 18em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .questionnaire-content {
      display: flex;
      align-items: center;

      img {
        float: left;
        padding: 0 20px;
      }

      p {
        margin: 0;
      }
    }
    .questionnaire-footer {
      text-align: center;
      padding: 20px 0;

      .questionnaire-question {
        font-weight: 500;
        padding: 10px 0;
      }
    }
  }
}
