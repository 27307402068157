.modal-footer .btn + .btn {
  margin-bottom: 5px;
}

.modal-footer {
  padding: 5px 15px;
}

.disclaimer {
  padding-top: 20px;
}
