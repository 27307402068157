%chalice-slider {
  .rc-slider {
    @media screen and (max-width: 768px) {
      width: 80%;
      margin: 0 auto;
    }
    @media screen and (max-width: 600px) {
      width: 90%;
      margin: 0 auto;
    }
  }

  .rc-slider-dot {
    background-color: transparent;
    border-color: transparent;
  }

  .rc-slider-rail {
    background-color: $theme-gold;
  }

  .rc-slider-dot {
    background-color: transparent;
    border-color: transparent;
  }

  .rc-slider-dot-active {
    background-color: $theme-color;
    border-color: $theme-color;
  }

  .rc-slider-track {
    background-color: $theme-color;
  }

  .rc-slider-handle {
    background-color: $theme-color;
    border-color: $theme-color;
  }

  .rc-slider-disabled {
    opacity: 0.5;
    background-color: transparent;
  }

  .rc-slider-mark-text {
    font-weight: 700;
  }
}

.calculator-cta {
  @extend %cta-button;

  display: block;
  font-size: 0.9rem;
  margin: 10px 0;
}

.phone-control {
  @extend %chalice-slider;
}

.calc-input-container {
  @extend %chalice-slider;

  max-width: 1000px;
  margin: 20px auto;
  padding: 0;

  .container-sliders {
    padding: 0 40px !important;
  }
}

.calc-input-container form:nth-child(even) {
  background-color: red;
}

.calc-input-container .row {
  margin-bottom: 10px;
}

.calc-container-graph {
  max-width: 1000px;
  margin: 0px 0px;
  padding: 0;
}

.table-container-graph {
  max-width: 1000px;
  /*margin: 0px auto;*/
  padding: 0;
}

.table-graph-header {
  text-align: center;
  font-weight: 600;
  border-right: 1px solid #ccc;
}

.header-row {
  padding: 0 !important;
  border-bottom: 1px solid #ccc;
}

.table-graph-cell {
  text-align: left;
  border-right: 1px solid #ccc;
  height: 100%
}

.table-graph-cell-total {
  text-align: left;
  border-right: 1px solid #ccc;
  font-weight: 600;
}

.input-margin-bottom {
  margin-bottom: 50px
}

.radio-margin-bottom {
  margin-bottom: 50px;
  height: 100%;
}

.has-error-radio input {
  outline: 1px solid red
}

.savings {
  font-weight: 700;
  margin: 0 !important;
}

.disclosure-wrapper {
  /*padding: 20px;*/
}

.disclosure {
  /*border: 1px solid #ccc;*/
  padding: 20px 0px;
  margin: 2px;
  font-size: 12px;
  padding-left: calc(100% - 85%);
}

.inter-disc {
  width: 90%;
  text-align: center;
}

.savings-row {
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: center;
}

.questions-row {
  padding-bottom: 5px;
  padding-top: 5px;
}

.calculator-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: $navbar-height;
}

.calculator-embedded {
  padding-top: 0 !important;
}

.donutDiv {
  width: 100%;
  height: 100%;
}

.centerLabel {
  position: absolute;
  top: 100px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 500;
  font-family: Montserrat, sans-se;
  padding-top: 25px;
  padding-left: 25%;
  padding-right: 25%;
  letter-spacing: -1px;
  pointer-events: none;
  @media screen and (max-width: 768px) and (min-width: 625px) {
    padding-left: 30%;
    padding-right: 30%;
  }

  .title {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .savings {
    font-weight: 600;
    color: $theme-color;
    font-size: 3.5rem;
  }

  .note {
    font-weight: 600;
    font-size: 1rem;
    color: $theme-black;
    padding: 0 10px;
  }
}

.donut {
  width: 100%;
  position: relative;
}

.donut-disabled {
  opacity: 0.9;
  pointer-events: none;
}

.title-text {
  font-family: Montserrat, sans-se;
  text-transform: none;
  margin: 0;
}

.titles {
  font-family: Montserrat, sans-se;
}

.savings-text {
  font-family: Montserrat, sans-se;
  color: black;
  text-transform: none;
  margin: 0;
  text-align: left;
}

.potential-text{
  text-transform: none;
  width: 100%;
  text-align: left;
  font-family: Montserrat, sans-se;
  color: black;
  padding-left: 5px;
  font-weight: 400;
  margin: 0;
  font-size: 30px;
  line-height: 45px;
  @media screen and (min-width: 768px) {
    top: 110px;
    padding-right: 60px;
    position: relative;
  }
}

.google-visualization-tooltip { pointer-events: none }

.col-sm-p-none {
  padding: 0 !important;
}

.deals-div {
  font-weight: 700;
}

.chart-container {
  margin-top: -30px;
}

.savings-table {
  margin-top: -30px;
  background-color: white;
  width: 100%;
}

.savings-container {
  width: 100%;
}

.product-name {
  width: 82%;
}

.savings-estimate {
  width: 18%;
}

.footer-row {
  width: 100% !important;
}

.full {
  display: block;
  height: 100%;
  width: 100%;
}

.ext-link-label {
  display: inline-block;
  text-align: center;
}

.ext-link-icon {
  float: right;
  padding-top: 10px;
  padding-right: 10px;
  color: black !important;
}

.header {
  text-align: center;
  padding-top: 18px !important;
}
