@import '../../styles/sass/_utils.scss';
@import '../../styles/sass/_helpers.scss';

$tile-radius: 5px;
$border-color: #e6e6e6;

.section_tiles {
  margin-bottom: 40px !important;
}

.tiles_container {
  display: flex;
  flex: 1 1 auto;
  flex-flow: wrap;
  flex-direction: row;
}

.more_section_tiles {
  margin-bottom: 40px !important;
}

.video {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0 !important;
}

.content_center {
  margin-top: 3%;
  text-align: center;
}

.tiles {
  @extend %theme-font;

  padding: 50px 0;
  margin: 0 -10px;

  @media #{$md-and-down} {
    margin: 0;
  }
}

.tile_arrangement_box {
  background-color: rgba(#fff, 0.9);
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  .tile_div {
    text-align: center;
  }

  .button_group {
    align-items: center;
    margin: 4px;

    .tile_button {
      font-size: 1.2rem;
      border-radius: 2px;
      margin: inherit;
      color: #fff;
      display: inline-block;
      padding: 6px 12px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      border: 1px solid transparent;
    }

    .save_btn {
      background-color: #5cb85c;
      border-color: #4cae4c;
    }

    .close_btn {
      background-color: #d43f3a;
      border-color: #d43f3a;
    }
  }
}

.content_padded_marketplace {
  padding: 0;
  padding-top: var(--chalice-marketplace-padding);
}

.filter_title {
  font-weight: 600;
  margin-bottom: 20px;

  @media #{$md-and-down} {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

@mixin gen-background($edge, $middle) {
  background: linear-gradient(90deg, $edge 35%, $middle 50%, $edge 65%);
  background-size: 200% 200%;
  animation: LoadingAnimation 1s ease-in-out infinite;
}

%loading-background {
  @include gen-background(#fafafa, #eee);
}

%loading-background-white {
  @include gen-background(transparent, rgba(#fafafa, 0.2));
}

.loading_text {
  @extend %loading-background;

  font-size: inherit;
  margin-bottom: 5px;
}

.bottom_marketplace {
  .tab-container {
    text-align: center;
    width: 50%;
    // right: 0;
    z-index: 1040;
    padding-inline-start: 0px !important;
  }

  ul {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &.profile-content {
    min-height: 320px;
  }
}

.marketplace_hero {
  padding: 50px 0;

  h1 {
    font-size: $theme-font-xxlarge;

    @media #{$md-and-down} {
      font-size: $theme-font-large;
    }
  }

  h2 {
    font-size: $theme-font-medium;
    font-weight: 500;
  }

  p {
    padding: 20px 0;
  }
}

.banner_fit {
  max-width: 100%;
  margin-right: auto !important;
  margin-left: auto !important;

  img {
    height: auto;
    width: 100%;
  }
}

.banner_container {
  display: block;

  @media #{$sm-and-down} {
    display: none;
  }

  & > div {
    position: relative;
  }
}

.banner-carousel {
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 2px;

  .slider {
    margin-bottom: 0 !important;
  }
}

.slider {
  &:hover {
    .slick-prev,
    .slick-next {
      background-color: rgba(#fff, 0.75);
    }
  }
}

.marketplace_tabs {
  // display: inline-block;
  // margin-right: 25px;
  list-style-type: none;
}

.marketplace_tabs > .marketplace_button {
  font-size: $theme-font-small;
  text-align: left;
}

.marketplace_button {
  font-size: 24px;
  padding-bottom: 15px;
  margin-top: 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  padding-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    font-size: 13px !important;
    padding-bottom: 10px !important;
  }

  @media screen and (max-width: 992px) {
    font-size: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  &.fname-lname {
    font-weight: 700;
    padding-bottom: 0 !important;
    font-size: 20px !important;
    margin-bottom: 5px;
  }
}

.marketplace_filter {
  background-color: #f7f8fa; // $theme-super-light-grey;
  padding: 50px 0;

  select {
    border-color: $border-color;
    border-radius: $tile-radius;
    padding: 5px 10px;
  }
}

.filter_title {
  font-weight: 600;
  margin-bottom: 20px;

  @media #{$md-and-down} {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

.bottom_marketplace {
  .tab-container {
    text-align: center;
    width: 50%;
    // right: 0;
    z-index: 1040;
    padding-inline-start: 0px !important;
  }

  ul {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &.profile-content {
    min-height: 320px;
  }
}

.active_tab {
  border-bottom: $theme-color solid 3px;
}

.center_tabs {
  padding: 10px 5px;
  text-align: center;
}

.pr_none {
  padding-right: 0 !important;
}

.pl_none {
  padding-left: 0 !important;
}

.cwp_review_faq_main {
  h4 {
    font-weight: bold;
  }
  .product_faq {
    padding: 10px;
   /* border-bottom: $theme-light-grey solid 1px;*/
  }

  .cwp_faq_tab_main {
    margin-top: 6%;
  }

  .product_review {
    .review_tagline {
      font-weight: 500;
      font-size: 1rem;
      padding-left: 5px;
    }

    padding: 10px;

    img {
      margin-bottom: 10px;
    }

    h5 {
      margin: 0 0 5px;
      color: $theme-black;
      font-size: 1.2rem;
    }

    .member_since,
    .member_stars {
      margin-bottom: 5px;

      & > span:first-child,
      & > span:first-child {
        color: $theme-black;
        font-weight: 500;
      }
    }

    .cwp_review_stars span:first-child {
      font-weight: 600;
    }

    .cwp_review_comment {
      font-size: 14px !important;
      margin-top: 10px;
    }

    .checked {
      color: #edab1e;
    }
  }

  .cwp_review_faq_card_scroll {
    max-height: 430px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .profile_category_title {
    padding: 15px 0;
  }

  .cwp_faq_inner_main .panel {
    min-height: initial;
  }

  .cwp_review_inner_main {
    .cwp_review_inner_card {
    }

    .cwp_review_inner_card:not(:last-child){
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 400px) {
  .cwp_review_inner_card .cwp_review_stars > span:last-child {
    display: block;
  }
}

.no-review-heading {
  font-weight: 600;
}

.panel .panel_heading .panel_title {
  color: $theme-black;
}

.panel-body {
  border: 0 !important;
  background-color: transparent;
  height: 100%;
}

.boxxed {
  border: 0;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.15);
}

.selected_dot {
  background-color: #0e2240;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 58%;
  margin-left: -50px;
  border: 1px solid #ccc;
  z-index: 99999;
}

.dot {
  background-color: #f1f1f1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 58%;
  margin-left: -50px;
  border: 1px solid #ccc;
  z-index: 99999;
}

.bg {
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    text-align: center;
    margin: 0 auto;
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.product_nav {
  position: sticky;
  top: 100px;
  background-color: white;
  z-index: 17;
  border-bottom: 1px solid #e4e4e4;

  ul {
    list-style-type: none;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0;
    margin: 0;

    @media #{$sm-and-down} {
      display: flex;
      justify-content: space-between;
    }

    li {
      div {
        line-height: 42px;
      }

      &.active {
        font-weight: 800;
        color: $theme-color;
      }

      &.product_nav_btn {
        display: none !important;
      }
      font-weight: 500;
      cursor: pointer;
      display: inline-block;
      padding-right: 1.5rem !important;

      @media #{$sm-and-down} {
        padding-right: 0 !important;
      }
    }
  }

  @media #{$sm-and-down} {
    font-size: .7rem !important;
  }
}

.action_btn_explore {
  @extend %cta-button;

  background-color: $theme-white;
  color: $theme-black;
  font-size: $theme-font-normal;
  border: #e6e6e6 solid 1px;

  &:hover {
    transform: scale(1.02);
    color: $theme-black;
  }
}

.action_btn_gld {
  @extend %cta-button;

  background-color: $theme-gold;
  color: $theme-white;
  font-size: $theme-font-normal;
  border: $theme-gold solid 1px;

  &:disabled {
    background-color: #ccc;
    &:hover {
      transform: none;
    }
  }

  &:hover {
    transform: scale(1.02);
  }
}

.action_btn_connect {
  @extend .action_btn_explore;

  max-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 10px 10px 0;

  &.shrink_on_small {
    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.8rem !important;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.action_btn_gld_product {
  @extend .action_btn_gld;

  max-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 10px 10px 0;

  &.shrink_on_small {
    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.8rem !important;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

%block-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
}

.product_container {
  @extend %block-container;

  position: relative !important;
  display: flex;

  &.related {
    display: block;
    margin-bottom: 50px;
  }

  .video_container {
    max-width: 450px;
    min-width: 450px;
    margin: 0 auto;
    border-radius: 2px;
    @media screen and (max-width: 768px) {
      width: calc(90vw);
      max-width: calc(90vw);
      min-width: calc(90vw);
    }
  }

  .product_text_container {
    flex-grow: 2;
    max-width: 950px;
    padding: 15px 0;
  }

  .product_banner {
    #media {
    }
    flex-grow: 1;
    align-self: flex-start;
    position: sticky;
    top: 172px;
    display: flex;
    padding-left: 20px;
  }
}

.double_rc_button {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
}

.product_block_blue {
  width: 100%;
  padding: 50px 0;

  .product_container {
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 50px;
  }
  .star_color {
    a {
      color: white;
    }
  }
}

.product {
  .tag_line {
    margin: 0px 0px 15px 0px;
  }
  h2 {
    padding: 15px 0px;
    font-size: 32px;
  }
}

.product .cwp_custom_panel_main .title {
  margin-bottom: 0;
}

.product {
  @extend %theme-font;

  padding-top: 150px;
  background-color: #fff;

  @media #{$md-and-down} {
    padding-top: 100px;
  }

  .slider {
    &:hover {
      .slick-prev,
      .slick-next {
        background-color: rgba(#fff, 0.75);
      }
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  .product_header {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .product_sticky_header {
    top: 0;
    padding: 20px 0 5px 0;
    position: sticky;
    background-color: $theme-white;
    z-index: 18;
  }
}

.product {
  .cwp_custom_panel_main .deal {
    margin-bottom: 0;
    padding: 10px 0;
  }

  .integration_connect {
    padding: 10px 0;
  }

  .consultation_row {
    padding: 0 0 10px 0;
  }

  .benefits_main {
    margin-bottom: 30px;
  }

  .tag_line {
    font-weight: 600 !important;
    font-size: 1.1rem;
    margin: 0 0 20px 0;
  }

  .cwp_review_faq_main {
    h4 {
      font-weight: bold;
    }
    .product_faq {
      padding: 10px 0;
     /* border-bottom: $theme-light-grey solid 1px;*/
    }

    .cwp_faq_tab_main {
      margin-top: 6%;
    }

    .product_review {
      .review_tagline {
        font-weight: 600;
        font-size: 1.1rem;
        padding-left: 5px;
      }

      padding: 10px 0 20px 0;
    /*  border-bottom: $theme-light-grey solid 1px;*/

      img {
        margin-bottom: 10px;
      }

      h5 {
        margin: 0 0 5px;
        color: $theme-black;
        font-size: 1.2rem;
      }

      .member_since,
      .member_stars {
        margin-bottom: 5px;

        & > span:first-child,
        & > span:first-child {
          color: $theme-black;
          font-weight: 500;
        }
      }

      .cwp_review_stars span:first-child {
        font-weight: 600;
      }

      .cwp_review_comment {
        font-size: 14px !important;
        margin-top: 10px;
      }

      .checked {
        color: #edab1e;
      }
    }

    .cwp_review_faq_card_scroll {
      max-height: 430px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .profile_category_title {
      padding: 15px 0;
    }

    .cwp_faq_inner_main .panel {
      min-height: initial;
    }

    .cwp_review_inner_main {
      .cwp_review_inner_card {
      }

      .cwp_review_inner_card:not(:last-child){
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 400px) {
    .cwp_review_inner_card .cwp_review_stars > span:last-child {
      display: block;
    }
  }
}

.allReviews {
  margin-top: 20px;
}

.benefits {
  margin-top: 30px !important;
  color: $theme-black;
}

.product_text {
  padding: 0 10px 0 0;
  font-size: 16px;

  img {
    max-width: 100%;
  }
}

.more_products {
  margin: 30px 0 20px 0 !important;
  color: $theme-black;
}

.loading_text {
  @extend %loading-background;

  font-size: inherit;
  margin-bottom: 5px;
}

.loading_text_white {
  @extend %loading-background-white;

  font-size: inherit;
  margin-bottom: 5px;
}

.description {
  margin-top: 5px !important;
  color: $theme-black;
}

.consultation_backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.consultation_container {
  position: fixed;
  width: 600px;
  z-index: 2040;
  top: 10%;
  left: 50%;
  margin-left: -250px;
  background-color: transparent;

  .consultation_content {
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0.5px 5px 0 #000;
  }

  .modal-body {
    padding: 30px;
  }

  .consultation_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: #ccc solid 1px;

    .consultation-title {
      font-weight: 500;
      font-size: 1.5rem;
      padding: 0;
      margin: 0;
    }

    h3 {
      text-align: left;
    }

    a {
      text-align: right;
    }
  }

  @media #{$sm-and-down} {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    margin-left: 0;

    .consultation_content {
      height: 100%;
    }
  }
}

.btn-green-action {
  background-color: $theme-tier-3;
  color: white;
  border: 2px solid $theme-tier-3;
  text-decoration: none;

  &:hover,
  &:visited,
  &:focus,
  &:active,
  &:active:focus {
    text-shadow: 0 0 0.65px white, 0 0 0.65px white;
    text-decoration: none !important;
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      text-shadow: none;
      opacity: 0.5;
    }
  }
}

.btn-product-popup {
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 15px;
  // width: 100%;
  transition: text-shadow 0.3s;
  border-radius: 2px;
  font-weight: 600;
}

.large_modal {
  overflow: scroll !important;
  top: 100px !important;
}

.upgrade_modal {
  position: fixed;
  width: 100%;
  z-index: 1040;
  bottom: 0px;
  background-color: $theme-light-grey;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  height: 40vh;
  padding: 40px 0 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow-y: scroll;

  @media #{$sm-and-down} {
    height: 67vh;
  }

  .modal_flex {
    display: flex;
    align-items: center;
    align-content: space-evenly;

    @media #{$sm-and-down} {
      flex-direction: column;
    }
    .interested_text {
      width: 50%;
      font-weight: 500;
      font-size: 1.2rem;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media #{$sm-and-down} {
        width: 100%;
      }
    }
  }
}

.empty_commitment {
  padding-bottom: 22px
}

.empty_wrapper {
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 50px 20px;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  gap: 20px;

  h2 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }

  p {
    color: #c9c9c9;
  }
}
