.add-button  {
  margin: 5px auto;
  text-align: center;
  padding: 10px;
  background-color: #337ab7;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
  width: 100%;
}

.cancel-button {
  margin: 5px auto;
  text-align: center;
  padding: 10px;
  background-color: #d9534f;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
  width: 100%;
}

.member-modal {
  position: fixed;
  width: 400px;
  z-index: 1040;
  top: 100px;
  left: calc((100vw - 400px)/2);
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);

  &.remove {
    height: 120px;
  }
  &.add {
    height: 500px;
    &.admin-add {
      height: 600px;
    }
  }
  &.edit {
    height: 300px;
  }
}

.add-member-modal-backdrop {
  position: 'fixed';
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.invite-memb-btn {
  margin: 5px auto;
  text-align: center;
  padding: 10px;
  background-color: #337ab7;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
}

.pending-invites {
  color: #0e2240;
  font-weight: 600;
}

.light-gray-row {
  background-color: #f9f9f9
}

.delete-memb-btn {
  text-align: center;
  padding: 2px 10px;
  background-color: #d9534f;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
}

.resend-btn {
  text-align: center;
  padding: 2px 10px;
  background-color: #337ab7;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
}

.invite-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tablebody-grid {
  letter-spacing: 0px;
  font-size: 13px;
  font-height: 400;
}

.grid-select {
  // margin: 0 auto;
  height: 22px;
  padding: 0px;
  letter-spacing: -.5px;
  max-width: 100px;
}

.organization-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.organization-buttons {
  display: flex;
  flex-direction: row;
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: auto !important;
  }
}

.trash-icon-button {
  display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    min-width: 10px !important;
    padding: 8px 10px !important;
    height: 100%;

    &.invite-delete {
      width: 16px
    }
}

.organization-table {
  select {
    height: 30px;
  }
}
